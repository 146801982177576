import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})

export class NewsListComponent implements OnInit, OnDestroy {
  private category: string;
  dtOptions: DataTables.Settings = {};
  public allData: any;
  private isDtInitialized: boolean = false;
  public addCatName: string;
  public addCatRoute: string;
  public editCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    // console.log(this.category);
    if(this.category === "news-list") {
      this.addCatName = 'Add News';
      this.addCatRoute = '/news/create-news';
      this.editCatRoute = '/news/edit-news';
    } else if(this.category === "gmp-list") {
      this.addCatName = 'Add ';
      this.addCatRoute = '/gmp/create-gmp';
      this.editCatRoute = '/gmp/edit-gmp';
    } else if(this.category === "regulatory-guidelines-list") {
      this.addCatName = 'Add ';
      this.addCatRoute = '/regulatory-guidelines/create-regulatory-guidelines';
      this.editCatRoute = '/regulatory-guidelines/edit-regulatory-guidelines';
    } else if(this.category === "business-list") {
      this.addCatName = 'Add ';
      this.addCatRoute = '/business/create-business';
      this.editCatRoute = '/business/edit-business';
    } else if(this.category === "products-services-list") {
      this.addCatName = 'Add ';
      this.addCatRoute = '/products-services/create-products-services';
      this.editCatRoute = '/products-services/edit-products-services';
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.showItem();
  }

  async showItem() {
    const that = this;
    let catTypeListApi: any;
    if(this.category === "news-list") {
      catTypeListApi = this.apiService.getAllNewsList();
    } else if(this.category === "gmp-list") {
      catTypeListApi = this.apiService.getAllGmpList();
    } else if(this.category === "regulatory-guidelines-list") {
      catTypeListApi = this.apiService.getAllGuidelinesList();
    } else if(this.category === "business-list") {
      catTypeListApi = this.apiService.getAllBusinessList();
    } else if(this.category === "products-services-list") {
      catTypeListApi = this.apiService.getAllProductList();
    }
    await catTypeListApi.subscribe((resp: any) => {
      console.log(resp.data);
      that.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async deleteItem(id, type) {
    if (id == undefined || id == '') {
      this.toastr.error('User Id is Invalid');
      return false;
    }
    if (
      id != undefined &&
      id != '' &&
      confirm('Are You Sure, You Want To Delete?')
    ) {
      // console.log(id, type);
      if (type.split(' ')[0] === 'Internal') {
        let catTypeInDltApi: any;
        if(this.category === "news-list") {
          catTypeInDltApi = this.apiService.deleteInternalNewsById(id);
        } else if(this.category === "gmp-list") {
          catTypeInDltApi = this.apiService.deleteInternalGmpById(id);
        } else if(this.category === "regulatory-guidelines-list") {
          catTypeInDltApi = this.apiService.deleteInternalGuidelinesById(id);
        } else if(this.category === "business-list") {
          catTypeInDltApi = this.apiService.deleteInternalBusinessById(id);
        } else if(this.category === "products-services-list") {
          catTypeInDltApi = this.apiService.deleteInternalProductById(id);
        }
        await catTypeInDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      } else if (type.split(' ')[0] === 'External') {
        let catTypeExDltApi: any;
        if(this.category === "news-list") {
          catTypeExDltApi = this.apiService.deleteExternalNewsById(id);
        } else if(this.category === "gmp-list") {
          catTypeExDltApi = this.apiService.deleteExternalGmpById(id);
        } else if(this.category === "regulatory-guidelines-list") {
          catTypeExDltApi = this.apiService.deleteExternalGuidelinesById(id);
        } else if(this.category === "business-list") {
          catTypeExDltApi = this.apiService.deleteExternalBusinessById(id);
        } else if(this.category === "products-services-list") {
          catTypeExDltApi = this.apiService.deleteExternalProductById(id);
        }
        await catTypeExDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
