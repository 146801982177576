<div class="w-50 mx-auto">
  <div class="register-logo">
    <a [routerLink]="['/admin-list']"><b>Admin</b></a>
  </div>

  <div class="card">
    <div class="card-body register-card-body">
      <p class="login-box-msg" *ngIf="admin_id === null">Register A New Membership</p>
      <p class="login-box-msg" *ngIf="admin_id !== null">Edit An Already Exist Member</p>

      <form [formGroup]="registerForm" (ngSubmit)="register()">
        <div class="input-group mb-3">
          <input
            formControlName="firstName"
            type="text"
            class="form-control"
            placeholder="First Name"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-user"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            formControlName="lastName"
            type="text"
            class="form-control"
            placeholder="Last Name"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-user"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            formControlName="email"
            type="email"
            class="form-control"
            placeholder="Email"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            formControlName="mobile"
            type="tel"
            maxLength="10"
            minLength="10"
            class="form-control"
            placeholder="Mobile No."
            (keypress)="onlyNumberKey($event)"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-phone"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3" *ngIf="admin_id === null">
          <input
            formControlName="password"
            type="password"
            class="form-control"
            placeholder="Password"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3" *ngIf="admin_id === null">
          <input
            formControlName="retypePassword"
            type="password"
            class="form-control"
            placeholder="Retype Password"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-7">
            <div class="icheck-primary">
              <input
                type="checkbox"
                id="agreeTerms"
                name="terms"
                value="agree"
              />
              <label for="agreeTerms">
                I agree to the <a href="#">terms</a>
              </label>
            </div>
          </div> -->
          <!-- /.col -->
          <div class="col-5">
            <!-- <button type="submit" class="btn btn-info btn-block">Submit</button> -->
            <app-button
              [type]="'submit'"
              [block]="true"
              [text]="'Submit'"
              [loading]="isAuthLoading"
              [color]="'info'"
              [disabled]="isAuthDisabled"
            ></app-button>
          </div>
          <div class="col-2"></div>
          <div class="col-5">
            <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary btn-block" [routerLink]="['/admin-list']">Cancel</button>
          </div>
          <!-- /.col -->
        </div>
      </form>

      <!-- <div class="social-auth-links text-center">
        <p>- OR -</p>
        <a href="#" class="btn btn-block btn-primary">
          <i class="fab fa-facebook mr-2"></i>
          Sign up using Facebook
        </a>
        <a href="#" class="btn btn-block btn-danger">
          <i class="fab fa-google-plus mr-2"></i>
          Sign up using Google+
        </a>
      </div>

    </div> -->
    <!-- <a [routerLink]="['/login']" class="text-center">I already have a membership</a> -->
    <!-- /.form-box -->
  </div>
  <!-- /.card -->
</div>
