<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a
              [routerLink]="[addSubCatRoute]"
              class="btn btn-info float-right mb-3"
              >Add Sub-Category</a
            >
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Sub-Category</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allSubCat?.length != 0">
                <tr *ngFor="let subCat of allSubCat; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ subCat.sub_category_name }}</td>
                  <td *ngIf="subCat.status === 'active'">Active</td>
                  <td *ngIf="subCat.status !== 'active'">Inactive</td>
                  <!-- <td>{{ news.email }}</td>
                  <td>01/21/2021</td>
                  <td>04/20/2021</td> -->
                  <td>
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-info"
                        data-bs-toggle="tooltip" title="Edit"
                        [routerLink]="[editSubCatRoute, subCat._id]"
                      >
                        <i class="fas fa-pen"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        data-bs-toggle="tooltip" title="Delete"
                        (click)="deleteItem(subCat._id)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allSubCat?.length == 0">
                <tr>
                  <td
                    colspan="5"
                    class="no-data-available"
                    style="text-align: center"
                  >
                    No data!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>