import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppButtonComponent } from './components/app-button/app-button.component';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { NewsListComponent } from './views/news-list/news-list.component';

import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule } from '@angular/common/http';
import { NewsComponent } from './views/news/news.component';
import { AdminListComponent } from './views/admin-list/admin-list.component';
import { DashboardVideoComponent } from './views/dashboard-video/dashboard-video.component';
import { DashboardVideoListComponent } from './views/dashboard-video-list/dashboard-video-list.component';
import { UserListComponent } from './views/user-list/user-list.component';
import { DashVideoSubcategoryComponent } from './views/dash-video-subcategory/dash-video-subcategory.component';
import { DashVideoSubcategoryListComponent } from './views/dash-video-subcategorylist/dash-video-subcategorylist.component';
import { PageHeaderComponent } from './pages/main/page-header/page-header.component';
import { FabricEditorComponent } from './utils/helpers/fabric-editor/fabric-editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule } from '@angular/forms';
import { NewsSubcategoryComponent } from './views/news-subcategory/news-subcategory.component';
import { NewsSubcategoryListComponent } from './views/news-subcategory-list/news-subcategory-list.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

import { FirstWordPipe } from './utils/pipes/first-word.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserLogDetailsComponent } from './views/user-log-details/user-log-details.component';
import { AllCategoriesComponent } from './views/all-categories/all-categories.component';
import { AllCategoriesListComponent } from './views/all-categories-list/all-categories-list.component';
import { GmpChangeDaysComponent } from './views/gmp-change-days/gmp-change-days.component';
import { ChangeCategoryNameComponent } from './views/change-category-name/change-category-name.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './views/terms-and-condition/terms-and-condition.component';
import { DisclaimerComponent } from './views/disclaimer/disclaimer.component';
import { AboutUsListComponent } from './views/about-us-list/about-us-list.component';
import { PrivacyPolicyListComponent } from './views/privacy-policy-list/privacy-policy-list.component';
import { TermsAndConditionListComponent } from './views/terms-and-condition-list/terms-and-condition-list.component';
import { DisclaimerListComponent } from './views/disclaimer-list/disclaimer-list.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    NewsListComponent,
    NewsComponent,
    AdminListComponent,
    DashboardVideoComponent,
    DashboardVideoListComponent,
    DashVideoSubcategoryComponent,
    DashVideoSubcategoryListComponent,
    UserListComponent,
    PageHeaderComponent,
    FabricEditorComponent,
    NewsSubcategoryComponent,
    NewsSubcategoryListComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    FirstWordPipe,
    UserLogDetailsComponent,
    AllCategoriesComponent,
    AllCategoriesListComponent,
    GmpChangeDaysComponent,
    ChangeCategoryNameComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    DisclaimerComponent,
    AboutUsListComponent,
    PrivacyPolicyListComponent,
    TermsAndConditionListComponent,
    DisclaimerListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModule,
    DataTablesModule,
    HttpClientModule,
    ColorPickerModule,
    CKEditorModule,
    FormsModule,
    NgSelectModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
