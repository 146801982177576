import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-dash-video-subcategorylist',
  templateUrl: './dash-video-subcategorylist.component.html',
  styleUrls: ['./dash-video-subcategorylist.component.scss']
})
export class DashVideoSubcategoryListComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  public allSubCat: any;
  private isDtInitialized: boolean = false;
  public addSubCatRoute: string;
  public editSubCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.addSubCatRoute = '/video-subcategory/create-video-subcategory';
      this.editSubCatRoute = '/video-subcategory/edit-video-subcategory';

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.showItem();
  }
  // if(this.category === "news-subcategory-list") {

  async showItem() {
    const that = this;
    let catTypeListApi: any;
    catTypeListApi = this.apiService.getAllsubcatVideo();
    await catTypeListApi.subscribe((resp: any) => {
      that.allSubCat = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async deleteItem(subCat_id) {
    if (subCat_id == undefined || subCat_id == '') {
      this.toastr.error('Sub-Category Id is Invalid');
      return false;
    }
    if (subCat_id != undefined && subCat_id != '' && confirm('Are You Sure, You Want to Delete?')) {
      let catTypeDltApi: any;
      catTypeDltApi = this.apiService.deletesubcatVideo(subCat_id);
      await catTypeDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
      });
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
