<nav class="main-header navbar navbar-expand navbar-dark navbar-info" id="scroll">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="toggleMenuSidebar.emit()"
        role="button"
        id="menu-bar-btn"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <!-- <a href="https://easyevals.com" class="nav-link">About</a> -->
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <!-- <a href="https://easyevals.com/contact" class="nav-link">Support</a> -->
    </li>
  </ul>

  <form class="form-inline ml-3" [formGroup]="searchForm" style="display: none">
    <div class="input-group input-group-sm">
      <input
        class="form-control form-control-navbar"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form>

  <ul class="navbar-nav ml-auto">
    <!-- <app-messages-dropdown-menu></app-messages-dropdown-menu>
    <app-notifications-dropdown-menu></app-notifications-dropdown-menu> -->
    <app-user-dropdown-menu></app-user-dropdown-menu>
  </ul>
</nav>
