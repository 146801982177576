import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-news-subcategory-list',
  templateUrl: './news-subcategory-list.component.html',
  styleUrls: ['./news-subcategory-list.component.scss']
})

export class NewsSubcategoryListComponent implements OnInit, OnDestroy {
  private category: string;
  dtOptions: DataTables.Settings = {};
  public allSubCat: any;
  private isDtInitialized: boolean = false;
  public addSubCatRoute: string;
  public editSubCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    // console.log(this.category);
    if(this.category === "news-subcategory-list") {
      this.addSubCatRoute = '/news-subcategory/create-news-subcategory';
      this.editSubCatRoute = '/news-subcategory/edit-news-subcategory';
    } else if(this.category === "gmp-subcategory-list") {
      this.addSubCatRoute = '/gmp-subcategory/create-gmp-subcategory';
      this.editSubCatRoute = '/gmp-subcategory/edit-gmp-subcategory';
    } else if(this.category === "regulatory-guidelines-subcategory-list") {
      this.addSubCatRoute = '/regulatory-guidelines-subcategory/create-regulatory-guidelines-subcategory';
      this.editSubCatRoute = '/regulatory-guidelines-subcategory/edit-regulatory-guidelines-subcategory';
    } else if(this.category === "business-subcategory-list") {
      this.addSubCatRoute = '/business-subcategory/create-business-subcategory';
      this.editSubCatRoute = '/business-subcategory/edit-business-subcategory';
    } else if(this.category === "products-services-subcategory-list") {
      this.addSubCatRoute = '/products-services-subcategory/create-products-services-subcategory';
      this.editSubCatRoute = '/products-services-subcategory/edit-products-services-subcategory';
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.showItem();
  }

  async showItem() {
    const that = this;
    let catTypeListApi: any;
    if(this.category === "news-subcategory-list") {
      catTypeListApi = this.apiService.getAllSubCatNewsList();
    } else if(this.category === "gmp-subcategory-list") {
      catTypeListApi = this.apiService.getAllSubCatGmpList();
    } else if(this.category === "regulatory-guidelines-subcategory-list") {
      catTypeListApi = this.apiService.getAllSubCatGuidelinesList();
    } else if(this.category === "business-subcategory-list") {
      catTypeListApi = this.apiService.getAllSubCatBusinessList();
    } else if(this.category === "products-services-subcategory-list") {
      catTypeListApi = this.apiService.getAllSubCatProductList();
    }
    await catTypeListApi.subscribe((resp: any) => {
      that.allSubCat = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async deleteItem(subCat_id) {
    if (subCat_id == undefined || subCat_id == '') {
      this.toastr.error('Sub-Category Id is Invalid');
      return false;
    }
    if (subCat_id != undefined && subCat_id != '' && confirm('Are You Sure, You Want to Delete?')) {
      let catTypeDltApi: any;
      if(this.category === "news-subcategory-list") {
        catTypeDltApi = this.apiService.deleteNewsSubcategoryById(subCat_id);
      } else if(this.category === "gmp-subcategory-list") {
        catTypeDltApi = this.apiService.deleteGmpSubcategoryById(subCat_id);
      } else if(this.category === "regulatory-guidelines-subcategory-list") {
        catTypeDltApi = this.apiService.deleteGuidelinesSubcategoryById(subCat_id);
      } else if(this.category === "business-subcategory-list") {
        catTypeDltApi = this.apiService.deleteBusinessSubcategoryById(subCat_id);
      } else if(this.category === "products-services-subcategory-list") {
        catTypeDltApi = this.apiService.deleteProductSubcategoryById(subCat_id);
      }
      await catTypeDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
      });
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
