import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-news-subcategory',
  templateUrl: './news-subcategory.component.html',
  styleUrls: ['./news-subcategory.component.scss']
})
export class NewsSubcategoryComponent implements OnInit {
  addSubcategoryForm: FormGroup;
  private category: string;
  public cancelSubCatRoute: string;
  private subCat_id: any;
  private _userId: string = localStorage.getItem("id");
  public isAuthLoading = false;
  public isAuthDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    // console.log(this.category);
    if(this.category === "news-subcategory") {
      this.cancelSubCatRoute = '/news-subcategory-list';
    } else if(this.category === "gmp-subcategory") {
      this.cancelSubCatRoute = '/gmp-subcategory-list';
    } else if(this.category === "regulatory-guidelines-subcategory") {
      this.cancelSubCatRoute = '/regulatory-guidelines-subcategory-list';
    } else if(this.category === "business-subcategory") {
      this.cancelSubCatRoute = '/business-subcategory-list';
    } else if(this.category === "products-services-subcategory") {
      this.cancelSubCatRoute = '/products-services-subcategory-list';
    }

    this.addSubcategoryForm = new FormGroup({
      subCategory: new FormControl(),
      subCatStatus: new FormControl(),
    });
    this.addSubcategoryForm = this.formBuilder.group({
      subCategory: ['', [Validators.required]],
      subCatStatus: ['', []],
    });

    this.subCat_id = this.route.snapshot.paramMap.get('subCat_id');
    if (this.subCat_id != undefined && this.subCat_id != '') {
      this.editSubCategory(this.subCat_id);
    } else {
      this.addSubcategoryForm.get('subCatStatus').setValue('active');
    }
  }

  async addSubCategory() {
    if (
      this.addSubcategoryForm.value.subCategory.trim() == undefined ||
      this.addSubcategoryForm.value.subCategory.trim() == ''
    ) {
      this.toastr.error('Please Enter Sub-Category');
      return false;
    }

    if (this.subCat_id != undefined && this.subCat_id != '') {
      this.updateNews(this.subCat_id);
    } else {
      let formData = {
        'sub_category_name': this.addSubcategoryForm.get('subCategory').value.trim(),
        'status': this.addSubcategoryForm.get('subCatStatus').value,
        'user_id': this._userId,
      }
      // console.log(formData);
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      let catTypeAddApi: any;
      if(this.category === "news-subcategory") {
        catTypeAddApi = this.apiService.addNewsSubcategory(JSON.stringify(formData));
      } else if(this.category === "gmp-subcategory") {
        catTypeAddApi = this.apiService.addGmpSubcategory(JSON.stringify(formData));
      } else if(this.category === "regulatory-guidelines-subcategory") {
        catTypeAddApi = this.apiService.addGuidelinesSubcategory(JSON.stringify(formData));
      } else if(this.category === "business-subcategory") {
        catTypeAddApi = this.apiService.addBusinessSubcategory(JSON.stringify(formData));
      } else if(this.category === "products-services-subcategory") {
        catTypeAddApi = this.apiService.addProductSubcategory(JSON.stringify(formData));////////////////
      }
      await catTypeAddApi.subscribe((result) => {
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.success(result.message);
          if(this.category === "news-subcategory") {
            this.router.navigateByUrl('/news-subcategory-list');
          } else if(this.category === "gmp-subcategory") {
            this.router.navigateByUrl('/gmp-subcategory-list');
          } else if(this.category === "regulatory-guidelines-subcategory") {
            this.router.navigateByUrl('/regulatory-guidelines-subcategory-list');
          } else if(this.category === "business-subcategory") {
            this.router.navigateByUrl('/business-subcategory-list');
          } else if(this.category === "products-services-subcategory") {
            this.router.navigateByUrl('/products-services-subcategory-list');
          }
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.error(result.message);
        }
      });
    }
  }

  async editSubCategory(subCat_id) {
    let dataForForm = {
      id: subCat_id,
    };
    // console.log(dataForForm);
    if (subCat_id) {
      let catTypeEditApi: any;
      if(this.category === "news-subcategory") {
        catTypeEditApi = this.apiService.getNewsSubCatById(dataForForm);
      } else if(this.category === "gmp-subcategory") {
        catTypeEditApi = this.apiService.getGmpSubCatById(dataForForm);
      } else if(this.category === "regulatory-guidelines-subcategory") {
        catTypeEditApi = this.apiService.getGuidelinesSubCatById(dataForForm);
      } else if(this.category === "business-subcategory") {
        catTypeEditApi = this.apiService.getBusinessSubCatById(dataForForm);
      } else if(this.category === "products-services-subcategory") {
        catTypeEditApi = this.apiService.getProductSubCatById(dataForForm);///////////////////////////
      }
      await catTypeEditApi.subscribe((result) => {
        if (result.success) {
          console.log(result);
          let newsData: any = result.getData;
          this.addSubcategoryForm.patchValue({
            subCategory: newsData.sub_category_name,
          });
          if(newsData.status == "active") {
            this.addSubcategoryForm.get('subCatStatus').setValue('active');
          }
        } else {
          this.toastr.error(result.message);
        }
      });
    } else {
      this.toastr.error('No Data Found');
    }
  }

  async updateNews(subCat_id) {
    let formData = {
      'sub_category_name': this.addSubcategoryForm.get('subCategory').value,
      'status': this.addSubcategoryForm.get('subCatStatus').value == "" || this.addSubcategoryForm.get('subCatStatus').value == "inactive" ? "inactive" : "active",
    }
    // console.log(formData);
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    let catTypeUpdateApi: any;
    if(this.category === "news-subcategory") {
      catTypeUpdateApi = this.apiService.updateNewsSubcategory(JSON.stringify(formData), subCat_id);
    } else if(this.category === "gmp-subcategory") {
      catTypeUpdateApi = this.apiService.updateGmpSubcategory(JSON.stringify(formData), subCat_id);
    } else if(this.category === "regulatory-guidelines-subcategory") {
      catTypeUpdateApi = this.apiService.updateGuidelinesSubcategory(JSON.stringify(formData), subCat_id);
    } else if(this.category === "business-subcategory") {
      catTypeUpdateApi = this.apiService.updateBusinessSubcategory(JSON.stringify(formData), subCat_id);
    } else if(this.category === "products-services-subcategory") {
      catTypeUpdateApi = this.apiService.updateProductSubcategory(JSON.stringify(formData), subCat_id);///////
    }
    await catTypeUpdateApi.subscribe((result) => {
      if (result.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.success(result.message);
        if(this.category === "news-subcategory") {
          this.router.navigateByUrl('/news-subcategory-list');
        } else if(this.category === "gmp-subcategory") {
          this.router.navigateByUrl('/gmp-subcategory-list');
        } else if(this.category === "regulatory-guidelines-subcategory") {
          this.router.navigateByUrl('/regulatory-guidelines-subcategory-list');
        } else if(this.category === "business-subcategory") {
          this.router.navigateByUrl('/business-subcategory-list');
        } else if(this.category === "products-services-subcategory") {
          this.router.navigateByUrl('/products-services-subcategory-list');
        }
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.error(result.message);
      }
    });

  }

}
