import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../utils/services/api.service';
import { AppService } from 'src/app/utils/services/app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public count: any;
  public CategoryName: any;
  public userData = JSON.parse(localStorage.getItem("userInfo"));
  constructor(
    private apiService: ApiService,
    private appService: AppService
  ) {
    this.appService.listen().subscribe((res:any) => {
      this.CategoryName = res;
    });
  }

  ngOnInit() {
    this.getAllCount();
    this.getAllCategoryName();
  }

  getAllCount() {
    this.apiService.getAllDashboardCount().subscribe((resp: any) => {
      if(resp.success) {
        this.count = resp;
      }
    });
  }

  getAllCategoryName() {
    this.apiService.getAllCategory().subscribe((res: any) => {
      if(res.success) {
        this.CategoryName = res;
      } else {
        console.log("Problem Getting Category Names");
      }
    });
  }
}
