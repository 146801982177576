import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-dropdown-menu',
  templateUrl: './user-dropdown-menu.component.html',
  styleUrls: ['./user-dropdown-menu.component.scss'],
})
export class UserDropdownMenuComponent implements OnInit {
  public user;
  public userData = JSON.parse(localStorage.getItem("userInfo"));

  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.user = this.appService.user;
  }

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    if (this.dropdownMenu) {
      this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
    }
  }

  hideDropdownMenu() {
    if (this.dropdownMenu) {
      this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
    }
  }

  async logout() {
    let getEmail = { email: this.userData.email };
    await this.apiService.onLogout(JSON.stringify(getEmail)).subscribe((result: any) => {
      if(result.success) {
        this.toastr.success(result.message);
        this.appService.logout();
      } else {
        this.toastr.error('Problem Logging Out');
      }
    });
  }
}
