import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gmp-change-days',
  templateUrl: './gmp-change-days.component.html',
  styleUrls: ['./gmp-change-days.component.scss']
})
export class GmpChangeDaysComponent implements OnInit {
  updateMembershipDaysForm: FormGroup;
  private getid: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.updateMembershipDaysForm = new FormGroup({
      membershipDays: new FormControl()
    });
    this.updateMembershipDaysForm = this.formBuilder.group({
      membershipDays: ['', [Validators.required,Validators.pattern("^[0-9]*$")]]
    });
    this.getMembershipDays();
  }

  getMembershipDays() {
    this.apiService.getMembershipDay().subscribe((res: any) => {
      let getData = res.data[0];
      console.log(getData);
      this.getid = getData._id;
      this.updateMembershipDaysForm.patchValue({
        membershipDays: getData.membershipDays
      });
    })
  }

  get g() { 
    return this.updateMembershipDaysForm.controls;
  }

  updateMembershipDays() {
    if (this.updateMembershipDaysForm.invalid) {
      if(this.g.membershipDays.errors.required) {
        this.toastr.error('Membership Days is Required');
      } else if(this.g.membershipDays.errors.pattern) {
        this.toastr.error('Membership Days Should be a Number');
      }
      return;
    }
    // JSON.stringify(this.updateMembershipDaysForm.value)
    console.log(this.updateMembershipDaysForm.value, this.getid);
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService.addNewMembershipDay(JSON.stringify(this.updateMembershipDaysForm.value), this.getid).subscribe((data: any) => {
      if(data.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.success(data.message);
        this.router.navigateByUrl('/gmp-list');
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.error(data.message);
      }
    });
  }

}
