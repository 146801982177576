import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-disclaimer-list',
  templateUrl: './disclaimer-list.component.html',
  styleUrls: ['./disclaimer-list.component.scss']
})
export class DisclaimerListComponent implements OnInit {

  private category: string;
  dtOptions: DataTables.Settings = {};
  public allData: any;
  private isDtInitialized: boolean = false;
  public addCatName: string;
  public addCatRoute: string;
  public editCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService) {}

  

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    // this.content_id = this.router.url.split('/')[2];
    // console.log(this.category);
    if(this.category === "disclaimer-list") {
      this.addCatName = 'Add Disclaimer';
      this.addCatRoute = '/disclaimer';
      this.editCatRoute = '/edit-disclaimer';
    } 

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.showItem();
  }

  async showItem() {
    console.log('hi')
    const that = this;
    let catTypeListApi: any;
    if(this.category === "disclaimer-list") {
      catTypeListApi = this.apiService.disclaimerList();
    } 
    await catTypeListApi.subscribe((resp: any) => {
      console.log(resp.data);
      that.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async deleteItem(id, type) {
    if (id == undefined || id == '') {
      this.toastr.error('User Id is Invalid');
      return false;
    }
    if (
      id != undefined &&
      id != '' &&
      confirm('Are You Sure, You Want To Delete?')
    ) {
      // console.log(id, type);
      if (type.split(' ')[0] === 'Internal') {
        let catTypeInDltApi: any;
        if(this.category === "news-list") {
          catTypeInDltApi = this.apiService.deleteInternalNewsById(id);
        } else if(this.category === "gmp-list") {
          catTypeInDltApi = this.apiService.deleteInternalGmpById(id);
        } else if(this.category === "regulatory-guidelines-list") {
          catTypeInDltApi = this.apiService.deleteInternalGuidelinesById(id);
        } else if(this.category === "business-list") {
          catTypeInDltApi = this.apiService.deleteInternalBusinessById(id);
        } else if(this.category === "products-services-list") {
          catTypeInDltApi = this.apiService.deleteInternalProductById(id);
        }
        await catTypeInDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      } else if (type.split(' ')[0] === 'External') {
        let catTypeExDltApi: any;
        if(this.category === "news-list") {
          catTypeExDltApi = this.apiService.deleteExternalNewsById(id);
        } else if(this.category === "gmp-list") {
          catTypeExDltApi = this.apiService.deleteExternalGmpById(id);
        } else if(this.category === "regulatory-guidelines-list") {
          catTypeExDltApi = this.apiService.deleteExternalGuidelinesById(id);
        } else if(this.category === "business-list") {
          catTypeExDltApi = this.apiService.deleteExternalBusinessById(id);
        } else if(this.category === "products-services-list") {
          catTypeExDltApi = this.apiService.deleteExternalProductById(id);
        }
        await catTypeExDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}

