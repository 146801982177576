<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
            <!-- form start -->
            <form [formGroup]="addTermsAndConditionForm" (ngSubmit)="AddAboutUsContent()" >
               <div class="card-header pb-3">
                
              </div> 
               <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                   
                  </div>
  
                  <div class="col-md-4">
                    
                  </div> 
   
                  <div  class="col-md-4" >
                   
                  </div>
                  <div    class="col-md-4" >
                    
                  </div>
                </div> 
  
               
                <div  class="row" >
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="class_name" >Content<span class= "required" ></span>
                      </label>
                      <ckeditor [config]="config_ck" formControlName="term_condition">
                       
                      </ckeditor>
                    </div>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-md-4">
                    <div class="custom-control custom-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="status"
                        formControlName="status"
                        (change)="addTermsAndConditionForm.get('status').setValue($event.target.checked ? 'Active' : 'Inactive')"
                        [checked]="addTermsAndConditionForm.value.status == 'Active'"
                      />
                      <label for="status" class="form-check-label">Active</label>
                    </div>
                  </div>
                </div> -->
  
              
                <div class="row">
                  <div class="col-md-4">
                    
                  </div>
                </div>
              </div>
             
              <div class="card-footer text-right">
                
                <app-button
                  [type]="'submit'"
                  [block]="false"
                  [text]="'Submit'"
                  [loading]="isAuthLoading"
                  [color]="'info'"
                  [disabled]="isAuthDisabled"
                ></app-button>
                <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="['/terms-and-conditions-list']" >Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- ================================================================================================ -->
  

