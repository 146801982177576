<!-- Main content -->
<section class="content" *ngIf="count">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div class="col-lg-3 col-6" *ngIf="CategoryName">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="inner">
            <h3>{{ count.newsCount }}</h3>
            <p>{{ CategoryName.Category_A }}</p>
          </div>
          <div class="icon">
            <i class="fas fa-newspaper"></i>
          </div>
          <a [routerLink]="['/news-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6" *ngIf="CategoryName">
        <!-- small box -->
        <div class="small-box bg-success">
          <div class="inner">
            <h3>{{ count.regulatoryCount }}</h3>
            <p>{{ CategoryName.Category_B }}</p>
          </div>
          <div class="icon">
            <i class="fas fa-gavel"></i>
          </div>
          <a [routerLink]="['/regulatory-guidelines-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6" *ngIf="CategoryName">
        <!-- small box -->
        <div class="small-box bg-warning">
          <div class="inner">
            <h3>{{ count.gmpCount }}</h3>
            <p>{{ CategoryName.Category_C }}</p>
          </div>
          <div class="icon">
            <i class="fas fa-industry"></i>
          </div>
          <a [routerLink]="['/gmp-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6" *ngIf="CategoryName">
        <!-- small box -->
        <div class="small-box bg-danger">
          <div class="inner">
            <h3>{{ count.businessCount }}</h3>
            <p>{{ CategoryName.Category_D }}</p>
          </div>
          <div class="icon">
            <i class="fas fa-briefcase"></i>
          </div>
          <a [routerLink]="['/business-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
    </div>
    <!-- /.row -->
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <div class="col-lg-3 col-6" *ngIf="CategoryName">
        <!-- small box -->
        <div class="small-box bg-success">
          <div class="inner">
            <h3>{{ count.productCount }}</h3>
            <p>{{ CategoryName.Category_E }}</p>
          </div>
          <div class="icon">
            <i class="fas fa-boxes"></i>
          </div>
          <a [routerLink]="['/products-services-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6" *ngIf="CategoryName">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="inner">
            <h3>{{ count.videoCount }}</h3>
            <p>{{ CategoryName.Category_F }}</p>
          </div>
          <div class="icon">
            <i class="fas fa-video"></i>
          </div>
          <a [routerLink]="['/videos-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-danger">
          <div class="inner">
            <h3>{{ count.userCount }}</h3>
            <p>Users</p>
          </div>
          <div class="icon">
            <i class="fas fa-users"></i>
          </div>
          <a [routerLink]="['/user-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-lg-3 col-6" *ngIf="+userData?.role === 0">
        <!-- small box -->
        <div class="small-box bg-warning">
          <div class="inner">
            <h3>{{ count.adminCount }}</h3>
            <p>Admins</p>
          </div>
          <div class="icon">
            <i class="fas fa-user-tie"></i>
          </div>
          <a [routerLink]="['/admin-list']" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <!-- ./col -->
    </div>

    <!-- ==================================================================================== -->
    <!-- /.row -->
    <!-- Small boxes (Stat box) -->
    <!-- <div class="row">
      <div class="col-lg-3 col-6"> -->
        <!-- small box -->
        <!-- <div class="small-box bg-info">
          <div class="inner">
            <h3>150</h3>
            <p>Institutes</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div> -->
      <!-- ./col -->
      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small box -->
        <!-- <div class="small-box bg-success">
          <div class="inner">
            <h3>150</h3>
            <p>Staffs</p>
          </div>
          <div class="icon">
            <i class="ion ion-stats-bars"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div> -->
      <!-- ./col -->
      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small box -->
        <!-- <div class="small-box bg-warning">
          <div class="inner">
            <h3>150</h3>
            <p>Students</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div> -->
      <!-- ./col -->
      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small box -->
        <!-- <div class="small-box bg-danger">
          <div class="inner">
            <h3>150</h3>
            <p>Exams</p>
          </div>
          <div class="icon">
            <i class="ion ion-pie-graph"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div> -->
      <!-- ./col -->
    <!-- </div> -->
    <!-- /.row -->
    <!-- Small boxes (Stat box) -->
    <!-- <div class="row">
      <div class="col-lg-6 col-6"> -->
        <!-- Custom tabs (Charts with tabs)-->
        <!-- <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              <i class="fas fa-chart-pie mr-1"></i>
              Sales
            </h3>
            <div class="card-tools">
              <ul class="nav nav-pills ml-auto">
                <li class="nav-item">
                  <a class="nav-link active" href="#revenue-chart" data-toggle="tab">Area</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#sales-chart" data-toggle="tab">Donut</a>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- /.card-header -->
          <!-- <div class="card-body">
            <div class="tab-content p-0"> -->
              <!-- Morris chart - Sales -->
              <!-- <div class="chart tab-pane active" id="revenue-chart"
                   style="position: relative; height: 300px;">
                  <canvas id="revenue-chart-canvas" height="300" style="height: 300px;"></canvas>                         
               </div>
              <div class="chart tab-pane" id="sales-chart" style="position: relative; height: 300px;">
                <canvas id="sales-chart-canvas" height="300" style="height: 300px;"></canvas>                         
              </div>  
            </div>
          </div> -->
          <!-- /.card-body -->
        <!-- </div> -->
        <!-- /.card -->
      <!-- </div> -->
      <!-- ./col -->
      <!-- <div class="col-lg-6 col-6"> -->
        <!-- Calendar -->
        <!-- <div class="card bg-gradient-success">
          <div class="card-header border-0">
            <h3 class="card-title">
              <i class="far fa-calendar-alt"></i>
              Calendar
            </h3> -->
            <!-- tools card -->
            <!-- <div class="card-tools"> -->
              <!-- button with a dropdown -->
              <!-- <div class="btn-group">
                <button type="button" class="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown">
                  <i class="fas fa-bars"></i></button>
                <div class="dropdown-menu float-right" role="menu">
                  <a href="#" class="dropdown-item">Add new event</a>
                  <a href="#" class="dropdown-item">Clear events</a>
                  <div class="dropdown-divider"></div>
                  <a href="#" class="dropdown-item">View calendar</a>
                </div>
              </div>
              <button type="button" class="btn btn-success btn-sm" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-success btn-sm" data-card-widget="remove">
                <i class="fas fa-times"></i>
              </button>
            </div> -->
            <!-- /. tools -->
          <!-- </div> -->
          <!-- /.card-header -->
          <!-- <div class="card-body pt-0"> -->
            <!--The calendar -->
            <!-- <div id="calendar" style="width: 100%"></div>
          </div> -->
          <!-- /.card-body -->
        <!-- </div> -->
        <!-- /.card -->
      <!-- </div> -->
      <!-- ./col -->
    <!-- </div> -->
    <!-- /.row -->
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->