<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
            <!-- form start -->
            <form [formGroup]="updateCategoryNameForm" (ngSubmit)="updateCategoryName()">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                              <label for="Category_A"
                                >Category A<span class="required"></span
                              ></label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="Category_A"
                                placeholder="Enter Sub Category"
                              />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                              <label for="Category_B"
                                >Category B<span class="required"></span
                              ></label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="Category_B"
                                placeholder="Enter Sub Category"
                              />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                              <label for="Category_C"
                                >Category C<span class="required"></span
                              ></label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="Category_C"
                                placeholder="Enter Sub Category"
                              />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                              <label for="Category_D"
                                >Category D<span class="required"></span
                              ></label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="Category_D"
                                placeholder="Enter Sub Category"
                              />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                              <label for="Category_E"
                                >Category E<span class="required"></span
                              ></label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="Category_E"
                                placeholder="Enter Sub Category"
                              />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                              <label for="Category_F"
                                >Category F<span class="required"></span
                              ></label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="Category_F"
                                placeholder="Enter Sub Category"
                              />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <!-- <button type="submit" class="btn btn-info mr-2">Submit</button> -->
                    <app-button
                      [type]="'submit'"
                      [block]="false"
                      [text]="'Submit'"
                      [loading]="isAuthLoading"
                      [color]="'info'"
                      [disabled]="isAuthDisabled"
                    ></app-button>
                    <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="['/']">Cancel</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</div>