import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {
  getData: any
  addTermsAndConditionForm: FormGroup
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public cancelCatRoute: string;
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    { name: 'links' }, { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    { name: 'styles' },
    { name: 'colors' }],
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div'
  }


  private _userId: string = localStorage.getItem("id");
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective
  category: string;
  footer_content: any;
  content_id: string;

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) { }
  // @ViewChild('importVdo', { static: false }) importVdo: ElementRef;
  // @ViewChild('importImg', { static: false }) importImg: ElementRef;
  // @ViewChild('importLogoImg', { static: false }) importLogoImg: ElementRef;

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    this.content_id = this.router.url.split('/')[2]
    console.log(this.category, 'route')
    console.log(this.content_id, 'id')
    this.addTermsAndConditionForm = new FormGroup({
      category_name: new FormControl(),
      term_condition: new FormControl(),
      status: new FormControl(),
    });
    this.addTermsAndConditionForm = this.formBuilder.group({

      // category_name: ['', [Validators.required]],    
      term_condition: [[], [Validators.required]],
      status: ['', []],
    });
    this.getContent()
  }
  AddAboutUsContent() {
    if (this.content_id == undefined) {

      if (
        this.addTermsAndConditionForm.value.term_condition.trim() == undefined ||
        this.addTermsAndConditionForm.value.term_condition.trim() == ''
      ) {
        this.toastr.error('Please Enter Content');
        return false;
      }
      let reqparams = {
        "user_id": this._userId,
        "status": this.addTermsAndConditionForm.get('status').value,
        "term_condition": this.addTermsAndConditionForm.get('term_condition').value,
        "category_name": "Terms & Conditions"
      };
      this.apiService.termcondition(reqparams).subscribe((result: any) => {
        if (result.success) {
         
          this.toastr.success(result.message);
          setTimeout(() => {
            this.router.navigateByUrl('/terms-and-conditions-list')
          }, 1000);
        
        }
        console.log(result)
      });
    }

    else {

      let id = this.getData._id
      let reqpara = {
        "term_condition": this.addTermsAndConditionForm.get('term_condition').value

      }
      console.log(id)
      this.apiService.editterm(id, reqpara).subscribe((result: any) => {
        if (result.success) {
          console.log(result.message)
          this.toastr.success(result.message);
          //this.router.navigate['/terms-and-conditions-list']
          this.router.navigateByUrl('/terms-and-conditions-list');
        }
      })

    }
  }
  getContent() {
    let data = {
      id: this.content_id
    }
    this.apiService.getTermsConditionsById(data).subscribe((res: any) => {
      console.log(res)
      if (res.success) {
        this.getData = res.getData
        console.log(this.getData)
        this.addTermsAndConditionForm.patchValue({
          term_condition: this.getData.term_condition
        })
        console.log('hi' + this.getData.term_condition)
      }
    })
  }

}
