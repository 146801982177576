import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { ApiService } from 'src/app/utils/services/api.service';

class BreadCrumb {
  title: string;
  url: string;
}
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  pageTitle: string = 'Dashboard';
  breadcrumbs: BreadCrumb[];
  public CategoryName: any;


  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private appService: AppService, 
    private apiService: ApiService
  ) { 
    this.appService.listen().subscribe((res:any) => {
      this.CategoryName = res;
    });
  }

  ngOnInit(): void {
    this.getAllCategoryName();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      if(data?.title.search("News") !== -1) {
        this.pageTitle = data?.title.replaceAll("News", this.CategoryName?.Category_A)
      } else if(data?.title.search("Guidelines") !== -1) {
        this.pageTitle = data?.title.replaceAll("Guidelines", this.CategoryName?.Category_B)
      } else if(data?.title.search("GMP") !== -1) {
        this.pageTitle = data?.title.replaceAll("GMP", this.CategoryName?.Category_C)
      } else if(data?.title.search("Business") !== -1) {
        this.pageTitle = data?.title.replaceAll("Business", this.CategoryName?.Category_D)
      } else if(data?.title.search("Products") !== -1) {
        this.pageTitle = data?.title.replaceAll("Products", this.CategoryName?.Category_E)
      } else if(data?.title.search("Video") !== -1) {
        //console.log(this.CategoryName,"Videosss");
        this.pageTitle = data?.title.replaceAll("Video", this.CategoryName?.Category_F)
        // this.pageTitle = data?.title;
      } else {
        this.pageTitle = data?.title;
      }
      console.log(this.pageTitle)
      console.log(data?.title)
      
      // for(let i = 0; i < data?.breadcrumb.length; i++) {
        //   if(data?.breadcrumb[i]?.title.search("News") !== -1) {
      //     data.breadcrumb[i].title = data.breadcrumb[i].title.replaceAll("News", this.CategoryName?.Category_A)
      //   } else if(data?.breadcrumb[i]?.title.search("Guidelines") !== -1) {
        //     data.breadcrumb[i].title = data.breadcrumb[i].title.replaceAll("Guidelines", this.CategoryName?.Category_B)
      //   } else if(data?.breadcrumb[i]?.title.search("GMP") !== -1) {
        //     data.breadcrumb[i].title = data.breadcrumb[i].title.replaceAll("GMP", this.CategoryName?.Category_C)
        //   } else if(data?.breadcrumb[i]?.title.search("Business") !== -1) {
          //     data.breadcrumb[i].title = data.breadcrumb[i].title.replaceAll("Business", this.CategoryName?.Category_D)
          //   } else if(data?.breadcrumb[i]?.title.search("Products") !== -1) {
            //     data.breadcrumb[i].title = data.breadcrumb[i].title.replaceAll("Products", this.CategoryName?.Category_E)
            //   } else if(data?.breadcrumb[i]?.title.search("Video") !== -1) {
              //     data.breadcrumb[i].title = data.breadcrumb[i].title.replaceAll("Video", this.CategoryName?.Category_F)
              //   }
              // }
              // this.breadcrumbs = data?.breadcrumb;
            });
    this.loadCurrentRoute();
          }
          
  private loadCurrentRoute(): any {
    this.getAllCategoryName()
    const root = this.router.routerState.snapshot.root;
    const routeData = this.lastChild(root);
    console.log(root)
    console.log(routeData)
    console.log(this.CategoryName?.Category_A)
    // this.pageTitle = routeData?.data?.title;
    // this.breadcrumbs = routeData?.data?.breadcrumb;
    if(routeData?.data?.title.search("News") !== -1) {
      this.pageTitle = routeData?.data?.title.replaceAll("News", this.CategoryName?.Category_A)
      console.log(this.CategoryName)
    } else if(routeData?.data?.title.search("Guidelines") !== -1) {
      this.pageTitle = routeData?.data?.title.replaceAll("Guidelines", this.CategoryName?.Category_B)
    } else if(routeData?.data?.title.search("GMP") !== -1) {
      this.pageTitle = routeData?.data?.title.replaceAll("GMP", this.CategoryName?.Category_C)
    } else if(routeData?.data?.title.search("Business") !== -1) {
      this.pageTitle = routeData?.data?.title.replaceAll("Business", this.CategoryName?.Category_D)
    } else if(routeData?.data?.title.search("Products") !== -1) {
      this.pageTitle = routeData?.data?.title.replaceAll("Products", this.CategoryName?.Category_E)
    } else if(routeData?.data?.title.search("Video") !== -1) {
      this.pageTitle = routeData?.data?.title.replaceAll("Video", this.CategoryName?.Category_F)
    } else {
      this.pageTitle = routeData?.data?.title;
    }
    // this.pageTitle =routeData?.data?.title
    console.log(routeData?.data?.title)
    console.log( this.pageTitle)
    // for(let i = 0; i < routeData?.data?.breadcrumb.length; i++) {
    //   if(routeData?.data?.breadcrumb[i]?.title.search("News") !== -1) {
    //     routeData.data.breadcrumb[i].title = routeData?.data.breadcrumb[i].title.replaceAll("News", this.CategoryName?.Category_A)
    //   } else if(routeData?.data?.breadcrumb[i]?.title.search("Guidelines") !== -1) {
    //     routeData.data.breadcrumb[i].title = routeData?.data.breadcrumb[i].title.replaceAll("Guidelines", this.CategoryName?.Category_B)
    //   } else if(routeData?.data?.breadcrumb[i]?.title.search("GMP") !== -1) {
    //     routeData.data.breadcrumb[i].title = routeData?.data.breadcrumb[i].title.replaceAll("GMP", this.CategoryName?.Category_C)
    //   } else if(routeData?.data?.breadcrumb[i]?.title.search("Business") !== -1) {
    //     routeData.data.breadcrumb[i].title = routeData?.data.breadcrumb[i].title.replaceAll("Business", this.CategoryName?.Category_D)
    //   } else if(routeData?.data?.breadcrumb[i]?.title.search("Products") !== -1) {
    //     routeData.data.breadcrumb[i].title = routeData?.data.breadcrumb[i].title.replaceAll("Products", this.CategoryName?.Category_E)
    //   } else if(routeData?.data?.breadcrumb[i]?.title.search("Video") !== -1) {
    //     routeData.data.breadcrumb[i].title = routeData?.data.breadcrumb[i].title.replaceAll("Video", this.CategoryName?.Category_F)
    //   }
    // }
    // this.breadcrumbs = routeData?.data?.breadcrumb;
  }

  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }

  getAllCategoryName() {
    this.apiService.getAllCategory().subscribe((res: any) => {
      if(res.success) {
        this.CategoryName = res;
        console.log(res)
      } else {
        console.log("Problem Getting Category Names");
      }
    });
  }

}
