<section class="content">
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Search By Login Date</label>
        <input type="date" class="form-control" ngbDatepicker (click)="datePicker.toggle()" [maxDate]="dateObj" #datePicker="ngbDatepicker" [(ngModel)]="selectedSearchDate" placeholder="dd-mm-yyyy" (ngModelChange)="searchItem($event)">
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table 
              datatable 
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" 
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Time</th>
                  <!-- <th>Action</th> -->
                </tr>
              </thead>
              <tbody *ngIf="allUser?.length != 0">
                <tr *ngFor="let user of allUser; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ user.firstname | titlecase }} {{ user.lastname | titlecase }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.loginLogout_at | date: 'h:mm a' }}</td>
                  <!-- <td>
                    <div class="btn-group">
                      <button 
                        *ngIf="user.status === 'active'"
                        type="button" 
                        class="btn btn-danger"
                        (click)="changeStaus(user._id, user.status)"
                      ><i class="fas fa-ban"></i></button>
                      <button 
                        *ngIf="user.status !== 'active'"
                        type="button" 
                        class="btn btn-success"
                        (click)="changeStaus(user._id, user.status)"
                      ><i class="far fa-check-circle"></i></button>
                    </div>
                  </td> -->
                </tr>
              </tbody>
              <tbody *ngIf="allUser?.length == 0">
                <tr>
                  <td colspan="4" class="no-data-available" style="text-align: center">No data!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>
  