import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-category-name',
  templateUrl: './change-category-name.component.html',
  styleUrls: ['./change-category-name.component.scss']
})
export class ChangeCategoryNameComponent implements OnInit {
  updateCategoryNameForm: FormGroup;
  private __categoryID: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;

  constructor(
    private formBuilder: FormBuilder, 
    private router:Router, 
    private apiService: ApiService,
    private appService: AppService, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.updateCategoryNameForm = new FormGroup({
      Category_A: new FormControl(),
      Category_B: new FormControl(),
      Category_C: new FormControl(),
      Category_D: new FormControl(),
      Category_E: new FormControl(),
      Category_F: new FormControl()
    });
    this.updateCategoryNameForm = this.formBuilder.group({
      Category_A: ['', [Validators.required]],
      Category_B: ['', [Validators.required]],
      Category_C: ['', [Validators.required]],
      Category_D: ['', [Validators.required]],
      Category_E: ['', [Validators.required]],
      Category_F: ['', [Validators.required]]
    });

    this.getCategoryNames();
  }

  getCategoryNames() {
    this.apiService.getAllCategory().subscribe((res: any) => {
      if(res.success) {
        this.__categoryID = res._id;
        this.updateCategoryNameForm.patchValue(res);
      } else {
        this.toastr.error("Problem Getting Category Names");
      }
    });
  }

  updateCategoryName() {
    if(this.updateCategoryNameForm.invalid) {
      this.toastr.error("All Category Are Required");
      return;
    }
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    console.log(this.updateCategoryNameForm.value, this.__categoryID)
    this.apiService.updateCategoryName(JSON.stringify(this.updateCategoryNameForm.value), this.__categoryID).subscribe((res: any) => {
      if(res.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.success(res.message);
        this.appService.filter(this.updateCategoryNameForm.value);
        this.router.navigateByUrl('/');
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.error(res.message);
      }
    })
  }

}
