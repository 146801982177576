import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiHost: string;
  login: string;
  checkEmail: string;
  checkOTP: string;
  resetPsw: string;
  logout: string;
  register: string;
  dashboardCount: string;
  getAdminList: string;
  getAdminById: string;
  updateAdmin: string;
  getUserList: string;
  // getUserDetail: string;
  searchByLoginTime : string;
  updateMembershipDate: string;
  getMembershipDate: string;
  status: string;
  getCategory: string;
  updateCategory: string;
  getNewsList: string;
  getGmpList: string;
  getGuidelinesList: string;
  getBusinessList: string;
  getProductList: string;
  getVideoList: string;
  getSubCatNewsList: string;
  getSubCatGmpList: string;
  getSubCatGuidelinesList: string;
  getSubCatBusinessList: string;
  getSubCatProductList: string;
  addExtNews: string;
  addExtGmp: string;
  addExtGuidelines: string;
  addExtBusiness: string;
  addExtProduct: string;
  addIntNews: string;
  addIntGmp: string;
  addIntGuidelines: string;
  addIntBusiness: string;
  addIntProduct: string;
  addVideo: string;
  addExternalVideo: string;
  addNewsSubcat: string;
  addGmpSubcat: string;
  addGuidelinesSubcat: string;
  addBusinessSubcat: string;
  addProductSubcat: string;
  getInNewsData: string;
  getInGmpData: string;
  getInGuidelinesData: string;
  getInBusinessData: string;
  getInProductData: string;
  getVideoData: string;
  getExternalVideoData: string;
  getExNewsData: string;
  getExGmpData: string;
  getExGuidelinesData: string;
  getExBusinessData: string;
  getExProductData: string;
  getNewsSubCatData: string;
  getGmpSubCatData: string;
  getGuidelinesSubCatData: string;
  getBusinessSubCatData: string;
  getProductSubCatData: string;
  editInternalNews: string;
  editInternalGmp: string;
  editInternalGuidelines: string;
  editInternalBusiness: string;
  editInternalProduct: string;
  editVideo: string;
  editExternalVideo: string;
  editExternalNews: string;
  editExternalGmp: string;
  editExternalGuidelines: string;
  editExternalBusiness: string;
  editExternalProduct: string;
  editNewsSubCat: string;
  editGmpSubCat: string;
  editGuidelinesSubCat: string;
  editBusinessSubCat: string;
  editProductSubCat: string;
  deleteInternalNews: string;
  deleteInternalGmp: string;
  deleteInternalGuidelines: string;
  deleteInternalBusiness: string;
  deleteInternalProduct: string;
  deleteVideo: string;
  deleteExternalVideo: string;
  deleteExternalNews: string;
  deleteExternalGmp: string;
  deleteExternalGuidelines: string;
  deleteExternalBusiness: string;
  deleteExternalProduct: string;
  deleteNewsSubcategory: string;
  deleteGmpSubcategory: string;
  deleteGuidelinesSubcategory: string;
  deleteBusinessSubcategory: string;
  deleteProductSubcategory: string;
  postsubcategoryVideo: string;
  getAllsubcategoryVideo: string;
  updatesubcategoryVideo: string;
  getsubcategoryVideoById: string;
  deletesubcategoryVideo: string;
  deleteUserdetails: string;
  postFooterData: string;
  getAboutUsData: string; 
  getDisclaimerData: string;
  getTermsAndConditionData: string;
  getPrivacyPolicyData: string;
  editAboutUs: string;
  editdisclaimer: string;
  edittermcondition: string;
  getAboutUsList: string;
  getPrivacyPolicyList: string;
  getTermsConditionsList: string;
  getDisclaimerList: string;
  getAboutUsDataById: string;
  getPrivacyPolicyDataById: string;
  getDisclaimerDataById: string;
  getTermsConditionsDataById: string;
  addprivacy: string;
  editprivacypolicy: string;
  addtermcondition: string;

  constructor(private http: HttpClient) {
    this.apiHost = environment.API_HOST;
    this.login = this.apiHost + `login`;
    this.checkEmail = this.apiHost + `admin/forgetUserPassword`;
    this.checkOTP = this.apiHost + `admin/verifyOTPForgetUserPassword`;
    this.resetPsw = this.apiHost + `admin/forgetUserPasswordUpdate`;
    this.logout = this.apiHost + `admin/logout`;
    this.register = this.apiHost + `user`;
    this.dashboardCount = this.apiHost + `admin/getAllDashboardCount`;
    this.getAdminList = this.apiHost + `admin/all-admin`;
    this.getAdminById = this.apiHost + `getUserById`;
    this.updateAdmin = this.apiHost + `user`;
    this.getUserList = this.apiHost + `all-user-website`;
    // this.getUserDetail = this.apiHost + `getLoginLogoutTime`;
    this.searchByLoginTime = this.apiHost + `loginLogoutTimeSearch`;
    this.updateMembershipDate = this.apiHost + `admin/addUserMemberDate`;
    this.getMembershipDate = this.apiHost + `admin/all-UserMemberDate`;
    this.status = this.apiHost + `user-status`;
    this.getCategory = this.apiHost + `admin/all-category`;
    this.updateCategory = this.apiHost + `admin/post-category`;
    // Delete all category names - `admin/all-category`
    this.getNewsList = this.apiHost + `admin/allnews`;
    this.getGmpList = this.apiHost + `admin/allGMP`;
    this.getGuidelinesList = this.apiHost + `admin/allRegulatory`;
    this.getBusinessList = this.apiHost + `admin/allBusiness`;
    this.getProductList = this.apiHost + `admin/allProduct`;
    this.getVideoList = this.apiHost + `admin/allVideo`;
    this.getSubCatNewsList = this.apiHost + `admin/all-sub-category`;
    this.getSubCatGmpList = this.apiHost + `admin/all-sub-category-GMP`;
    this.getSubCatGuidelinesList = this.apiHost + `admin/all-sub-category-Regulatory`;
    this.getSubCatBusinessList = this.apiHost + `admin/all-sub-category-Business`;
    this.getSubCatProductList = this.apiHost + `admin/all-sub-category-Product`;
    this.addExtNews = this.apiHost + `admin/post-external-news`;
    this.addExtGmp = this.apiHost + `admin/post-external-GMP`;
    this.addExtGuidelines = this.apiHost + `admin/post-external-Regulatory`;
    this.addExtBusiness = this.apiHost + `admin/post-external-Business`;
    this.addExtProduct = this.apiHost + `admin/post-external-Product`;
    this.addIntNews = this.apiHost + `admin/post-internal-news`;
    this.addIntGmp = this.apiHost + `admin/post-internal-GMP`;
    this.addIntGuidelines = this.apiHost + `admin/post-internal-Regulatory`;
    this.addIntBusiness = this.apiHost + `admin/post-internal-Business`;
    this.addIntProduct = this.apiHost + `admin/post-internal-Product`;
    this.addVideo = this.apiHost + `admin/post-Video`;
    this.addExternalVideo = this.apiHost + `admin/post-Video-external`;
    this.addNewsSubcat = this.apiHost + `admin/post-sub-category`;
    this.addGmpSubcat = this.apiHost + `admin/post-sub-category-GMP`;
    this.addGuidelinesSubcat = this.apiHost + `admin/post-sub-category-Regulatory`;
    this.addBusinessSubcat = this.apiHost + `admin/post-sub-category-Business`;
    this.addProductSubcat = this.apiHost + `admin/post-sub-category-Product`;
    this.getInNewsData = this.apiHost + `admin/get-internal-newsById`;
    this.getInGmpData = this.apiHost + `admin/get-internal-GMPById`;
    this.getInGuidelinesData = this.apiHost + `admin/get-internal-RegulatoryById`;
    this.getInProductData = this.apiHost + `admin/get-internal-ProductById`;
    this.getInBusinessData = this.apiHost + `admin/get-internal-BusinessById`;
    this.getVideoData = this.apiHost + `admin/get-VideoById`;
    this.getExternalVideoData = this.apiHost + `admin/get-external-VideosById`;
    this.getExNewsData = this.apiHost + `admin/get-external-newsById`;
    this.getExGmpData = this.apiHost + `admin/get-external-GMPById`;
    this.getExGuidelinesData = this.apiHost + `admin/get-external-RegulatoryById`;
    this.getExBusinessData = this.apiHost + `admin/get-external-BusinessById`;
    this.getExProductData = this.apiHost + `admin/get-external-ProductById`;
    this.getNewsSubCatData = this.apiHost + `admin/get-sub-categoryById`;
    this.getGmpSubCatData = this.apiHost + `admin/get-sub-categoryGMPById`;
    this.getGuidelinesSubCatData = this.apiHost + `admin/get-sub-categoryRegulatoryById`;
    this.getBusinessSubCatData = this.apiHost + `admin/get-sub-categoryBusinessById`;
    this.getProductSubCatData = this.apiHost + `admin/get-sub-categoryProductById`;
    this.editInternalNews = this.apiHost + `admin/post-internal-news`;
    this.editInternalGmp = this.apiHost + `admin/post-internal-GMP`;
    this.editInternalGuidelines = this.apiHost + `admin/post-internal-Regulatory`;
    this.editInternalBusiness = this.apiHost + `admin/post-internal-Business`;
    this.editInternalProduct = this.apiHost + `admin/post-internal-Product`;
    this.editVideo = this.apiHost + `admin/post-Video`;
    this.editExternalVideo = this.apiHost + `admin/post-external-Videos`;
    this.editExternalNews = this.apiHost + `admin/post-external-news`;
    this.editExternalGmp = this.apiHost + `admin/post-external-GMP`;
    this.editExternalGuidelines = this.apiHost + `admin/post-external-Regulatory`;
    this.editExternalBusiness = this.apiHost + `admin/post-external-Business`;
    this.editExternalProduct = this.apiHost + `admin/post-external-Product`;
    this.editNewsSubCat = this.apiHost + `admin/post-sub-category`;
    this.editGmpSubCat = this.apiHost + `admin/post-sub-category-GMP`;
    this.editGuidelinesSubCat = this.apiHost + `admin/post-sub-category-Regulatory`;
    this.editBusinessSubCat = this.apiHost + `admin/post-sub-category-Business`;
    this.editProductSubCat = this.apiHost + `admin/post-sub-category-Product`;
    this.deleteInternalNews = this.apiHost + `admin/post-internal-news`;
    this.deleteInternalGmp = this.apiHost + `admin/post-internal-GMP`;
    this.deleteInternalGuidelines = this.apiHost + `admin/post-internal-Regulatory`;
    this.deleteInternalBusiness = this.apiHost + `admin/post-internal-Business`;
    this.deleteInternalProduct = this.apiHost + `admin/post-internal-Product`;
    this.deleteVideo = this.apiHost + `admin/post-Video`;
    this.deleteExternalVideo = this.apiHost + `admin/post-external-Videos`;
    this.deleteExternalNews = this.apiHost + `admin/post-external-news`;
    this.deleteExternalGmp = this.apiHost + `admin/post-external-GMP`;
    this.deleteExternalGuidelines = this.apiHost + `admin/post-external-Regulatory`;
    this.deleteExternalBusiness = this.apiHost + `admin/post-external-Business`;
    this.deleteExternalProduct = this.apiHost + `admin/post-external-Product`;
    this.deleteNewsSubcategory = this.apiHost + `admin/post-sub-category`;
    this.deleteGmpSubcategory = this.apiHost + `admin/post-sub-category-GMP`;
    this.deleteGuidelinesSubcategory = this.apiHost + `admin/post-sub-category-Regulatory`;
    this.deleteBusinessSubcategory = this.apiHost + `admin/post-sub-category-Business`;
    this.deleteProductSubcategory = this.apiHost + `admin/post-sub-category-Product`;
    this.postsubcategoryVideo = this.apiHost + `admin/post-sub-category-Video`;
    this.getAllsubcategoryVideo = this.apiHost + `admin/all-sub-category-Video`;
    this.updatesubcategoryVideo = this.apiHost + `admin/post-sub-category-Video`;
    this.getsubcategoryVideoById = this.apiHost + `admin/get-sub-categoryVideoById`;
    this.deletesubcategoryVideo = this.apiHost + `admin/post-sub-category-Video`;
    this.deleteUserdetails = this.apiHost + `admin/delete-admin`;
    this.postFooterData = this.apiHost + `admin/post-footer-content`;
    this.getAboutUsData = this.apiHost + `admin/get-about-us`;
    this.getDisclaimerData = this.apiHost + `admin/get-disclaimer`;
    this.getTermsAndConditionData = this.apiHost + `admin/get-terms-conditions`;
    this.getPrivacyPolicyData = this.apiHost + `admin/all-privacy-policy`;
    this.editAboutUs = this.apiHost + `admin/post-about-us`;
    this.editdisclaimer = this.apiHost + `admin/put-disclaimer`;
    this.edittermcondition = this.apiHost + `admin/put-term-condition`;
    this.getAboutUsList = this.apiHost + `admin/all-about-us`;
    this.getPrivacyPolicyList = this.apiHost + `admin/all-privacy-policy`;
    this.getTermsConditionsList = this.apiHost + `admin/all-term-condition`;
    this.getDisclaimerList = this.apiHost + `admin/all-disclaimer`;
    this.getAboutUsDataById = this.apiHost + `admin/get-about-usById`;
    this.getPrivacyPolicyDataById = this.apiHost + `admin/get-privacypolicy-ById`;
    this.getDisclaimerDataById = this.apiHost + `admin/get-disclaimer-usById`;
    this.getTermsConditionsDataById = this.apiHost + `admin/get-termcondition-usById`;
    this.addprivacy = this.apiHost + 'admin/post-privacy';
    this.editprivacypolicy = this.apiHost + 'admin/put-privacy-policy';
    this.addtermcondition = this.apiHost + `admin/term-condition`;
  }

//////////////////////////////////////////////////////////////////////////////////////////////////////////

  onLogin(requestParameters) {
    return this.http.post(`${this.login}`, JSON.parse(requestParameters), {});
  }

  checkEmailForgotPsw(requestParameters) {
    return this.http.post(`${this.checkEmail}`, JSON.parse(requestParameters), {});
  }

  checkOTPForgotPsw(requestParameters) {
    return this.http.post(`${this.checkOTP}`, JSON.parse(requestParameters), {});
  }

  resetForgotPsw(requestParameters) {
    return this.http.post(`${this.resetPsw}`, JSON.parse(requestParameters), {});
  }

  onLogout(requestParameters) {
    return this.http.post(`${this.logout}`, JSON.parse(requestParameters), {});
  }

  onRegister(requestParameters) {
    return this.http.post(`${this.register}`, JSON.parse(requestParameters), {});
  }

  getAllAdmin() {
    return this.http.get(`${this.getAdminList}`);
  }

  getAdmin(requestParameters) {
    return this.http.post(`${this.getAdminById}`, JSON.parse(requestParameters), {});
  }

  updateAdminById(id, requestParameters) {
    return this.http.put(`${this.updateAdmin}?id=${id}`, JSON.parse(requestParameters), {});
  }

  getAllUsers() {
    return this.http.get(`${this.getUserList}`);
  }

  changeStausById(id, requestParameters) {
    return this.http.put(`${this.status}?id=${id}`, JSON.parse(requestParameters), {});
  }

  // getUsersDetails() {
  //   return this.http.get(`${this.getUserDetail}`);
  // }

  getUserLoginDetails(requestParameters) {
    return this.http.post(`${this.searchByLoginTime}`, requestParameters, {});
  }
  deleteUserLoginDetails(id) {
    return this.http.delete(`${this.deleteUserdetails}?id=${id}`, {});
  }

  addNewMembershipDay(requestParameters, id) {
    return this.http.put(`${this.updateMembershipDate}?id=${id}`, JSON.parse(requestParameters), {});
  }

  getMembershipDay() {
    return this.http.get(`${this.getMembershipDate}`);
  }

  getAllCategory() {
    return this.http.get(`${this.getCategory}`);
  }

  updateCategoryName(requestParameters, id) {
    return this.http.put(`${this.updateCategory}?id=${id}`, JSON.parse(requestParameters), {})
  }

  addExternalNews(requestParameters) {
    return this.http.post(`${this.addExtNews}`, requestParameters, {});
  }

  addExternalGmp(requestParameters) {
    return this.http.post(`${this.addExtGmp}`, requestParameters, {});
  }

  addExternalGuidelines(requestParameters) {
    return this.http.post(`${this.addExtGuidelines}`, requestParameters, {});
  }

  addExternalBusiness(requestParameters) {
    return this.http.post(`${this.addExtBusiness}`, requestParameters, {});
  }

  addExternalProduct(requestParameters) {
    return this.http.post(`${this.addExtProduct}`, requestParameters, {});
  }
  
  addInternalNews(requestParameters) {
    return this.http.post(`${this.addIntNews}`, requestParameters, {});
  }
  
  addInternalGmp(requestParameters) {
    return this.http.post(`${this.addIntGmp}`, requestParameters, {});
  }
  
  addInternalGuidelines(requestParameters) {
    return this.http.post(`${this.addIntGuidelines}`, requestParameters, {});
  }
  
  addInternalBusiness(requestParameters) {
    return this.http.post(`${this.addIntBusiness}`, requestParameters, {});
  }
  
  addInternalProduct(requestParameters) {
    return this.http.post(`${this.addIntProduct}`, requestParameters, {});
  }
  
  addNewVideo(requestParameters) {
    return this.http.post(`${this.addVideo}`, requestParameters, {});
  }

  addNewExternalVideo(requestParameters) {
    return this.http.post(`${this.addExternalVideo}`, requestParameters, {});
  }
  
  addNewsSubcategory(requestParameters) {
    return this.http.post(`${this.addNewsSubcat}`, JSON.parse(requestParameters), {});
  }
  
  addGmpSubcategory(requestParameters) {
    return this.http.post(`${this.addGmpSubcat}`, JSON.parse(requestParameters), {});
  }
  
  addGuidelinesSubcategory(requestParameters) {
    return this.http.post(`${this.addGuidelinesSubcat}`, JSON.parse(requestParameters), {});
  }
  
  addBusinessSubcategory(requestParameters) {
    return this.http.post(`${this.addBusinessSubcat}`, JSON.parse(requestParameters), {});
  }
  
  addProductSubcategory(requestParameters) {
    return this.http.post(`${this.addProductSubcat}`, JSON.parse(requestParameters), {});
  }
  
  getInNewsById(requestParameters) {
    return this.http.post(`${this.getInNewsData}`, requestParameters, {});
  }
  
  getInGmpById(requestParameters) {
    return this.http.post(`${this.getInGmpData}`, requestParameters, {});
  }
  
  getInGuidelinesById(requestParameters) {
    return this.http.post(`${this.getInGuidelinesData}`, requestParameters, {});
  }
  
  getInBusinessById(requestParameters) {
    return this.http.post(`${this.getInBusinessData}`, requestParameters, {});
  }
  
  getInProductById(requestParameters) {
    return this.http.post(`${this.getInProductData}`, requestParameters, {});
  }
  
  getVideoById(requestParameters) {
    return this.http.post(`${this.getVideoData}`, requestParameters, {});
  }

  getExternalVideoById(requestParameters) {
    return this.http.post(`${this.getExternalVideoData}`, requestParameters, {});
  }
  
  getExNewsById(requestParameters) {
    return this.http.post(`${this.getExNewsData}`, requestParameters, {});
  }
  
  getExGmpById(requestParameters) {
    return this.http.post(`${this.getExGmpData}`, requestParameters, {});
  }
  
  getExGuidelinesById(requestParameters) {
    return this.http.post(`${this.getExGuidelinesData}`, requestParameters, {});
  }
  
  getExBusinessById(requestParameters) {
    return this.http.post(`${this.getExBusinessData}`, requestParameters, {});
  }
  
  getExProductById(requestParameters) {
    return this.http.post(`${this.getExProductData}`, requestParameters, {});
  }
  
  getNewsSubCatById(requestParameters) {
    return this.http.post(`${this.getNewsSubCatData}`, requestParameters, {});
  }
  
  getGmpSubCatById(requestParameters) {
    return this.http.post(`${this.getGmpSubCatData}`, requestParameters, {});
  }
  
  getGuidelinesSubCatById(requestParameters) {
    return this.http.post(`${this.getGuidelinesSubCatData}`, requestParameters, {});
  }
  
  getBusinessSubCatById(requestParameters) {
    return this.http.post(`${this.getBusinessSubCatData}`, requestParameters, {});
  }
  
  getProductSubCatById(requestParameters) {
    return this.http.post(`${this.getProductSubCatData}`, requestParameters, {});
  }
  
  updateInternalNews(requestParameters, id) {
    return this.http.put(`${this.editInternalNews}?id=${id}`, requestParameters, {});
  }
  
  updateInternalGmp(requestParameters, id) {
    return this.http.put(`${this.editInternalGmp}?id=${id}`, requestParameters, {});
  }
  
  updateInternalGuidelines(requestParameters, id) {
    return this.http.put(`${this.editInternalGuidelines}?id=${id}`, requestParameters, {});
  }
  
  updateInternalBusiness(requestParameters, id) {
    return this.http.put(`${this.editInternalBusiness}?id=${id}`, requestParameters, {});
  }
  
  updateInternalProduct(requestParameters, id) {
    return this.http.put(`${this.editInternalProduct}?id=${id}`, requestParameters, {});
  }
  
  updateVideo(requestParameters, id) {
    return this.http.put(`${this.editVideo}?id=${id}`, requestParameters, {});
  }

  updateExternalVideo(requestParameters, id) {
    return this.http.put(`${this.editExternalVideo}?id=${id}`, requestParameters, {});
  }
  
  updateExternalNews(requestParameters, id) {
    return this.http.put(`${this.editExternalNews}?id=${id}`, requestParameters, {});
  }
  
  updateExternalGmp(requestParameters, id) {
    return this.http.put(`${this.editExternalGmp}?id=${id}`, requestParameters, {});
  }
  
  updateExternalGuidelines(requestParameters, id) {
    return this.http.put(`${this.editExternalGuidelines}?id=${id}`, requestParameters, {});
  }
  
  updateExternalBusiness(requestParameters, id) {
    return this.http.put(`${this.editExternalBusiness}?id=${id}`, requestParameters, {});
  }
  
  updateExternalProduct(requestParameters, id) {
    return this.http.put(`${this.editExternalProduct}?id=${id}`, requestParameters, {});
  }
  
  updateNewsSubcategory(requestParameters, id) {
    return this.http.put(`${this.editNewsSubCat}?id=${id}`, JSON.parse(requestParameters), {});
  }
  
  updateGmpSubcategory(requestParameters, id) {
    return this.http.put(`${this.editGmpSubCat}?id=${id}`, JSON.parse(requestParameters), {});
  }
  
  updateGuidelinesSubcategory(requestParameters, id) {
    return this.http.put(`${this.editGuidelinesSubCat}?id=${id}`, JSON.parse(requestParameters), {});
  }
  
  updateBusinessSubcategory(requestParameters, id) {
    return this.http.put(`${this.editBusinessSubCat}?id=${id}`, JSON.parse(requestParameters), {});
  }
  
  updateProductSubcategory(requestParameters, id) {
    return this.http.put(`${this.editProductSubCat}?id=${id}`, JSON.parse(requestParameters), {});
  }
  
  deleteInternalNewsById(requestParameters) {
    return this.http.delete(`${this.deleteInternalNews}?id=${requestParameters}`);
  }
  
  deleteInternalGmpById(requestParameters) {
    return this.http.delete(`${this.deleteInternalGmp}?id=${requestParameters}`);
  }
  
  deleteInternalGuidelinesById(requestParameters) {
    return this.http.delete(`${this.deleteInternalGuidelines}?id=${requestParameters}`);
  }
  
  deleteInternalBusinessById(requestParameters) {
    return this.http.delete(`${this.deleteInternalBusiness}?id=${requestParameters}`);
  }
  
  deleteInternalProductById(requestParameters) {
    return this.http.delete(`${this.deleteInternalProduct}?id=${requestParameters}`);
  }
  
  deleteVideoById(requestParameters) {
    return this.http.delete(`${this.deleteVideo}?id=${requestParameters}`);
  }

  deleteExternalVideoById(requestParameters) {
    return this.http.delete(`${this.deleteExternalVideo}?id=${requestParameters}`);
  }
  
  deleteExternalNewsById(requestParameters) {
    return this.http.delete(`${this.deleteExternalNews}?id=${requestParameters}`);
  }
  
  deleteExternalGmpById(requestParameters) {
    return this.http.delete(`${this.deleteExternalGmp}?id=${requestParameters}`);
  }
  
  deleteExternalGuidelinesById(requestParameters) {
    return this.http.delete(`${this.deleteExternalGuidelines}?id=${requestParameters}`);
  }
  
  deleteExternalBusinessById(requestParameters) {
    return this.http.delete(`${this.deleteExternalBusiness}?id=${requestParameters}`);
  }
  
  deleteExternalProductById(requestParameters) {
    return this.http.delete(`${this.deleteExternalProduct}?id=${requestParameters}`);
  }
  
  deleteNewsSubcategoryById(requestParameters){
    return this.http.delete(`${this.deleteNewsSubcategory}?id=${requestParameters}`);
  }
  
  deleteGmpSubcategoryById(requestParameters) {
    return this.http.delete(`${this.deleteGmpSubcategory}?id=${requestParameters}`);
  }
  
  deleteGuidelinesSubcategoryById(requestParameters) {
    return this.http.delete(`${this.deleteGuidelinesSubcategory}?id=${requestParameters}`);
  }
  
  deleteBusinessSubcategoryById(requestParameters) {
    return this.http.delete(`${this.deleteBusinessSubcategory}?id=${requestParameters}`);
  }
  
  deleteProductSubcategoryById(requestParameters) {
    return this.http.delete(`${this.deleteProductSubcategory}?id=${requestParameters}`);
  }
  
  getAllNewsList() {
    return this.http.get(`${this.getNewsList}`);
  }
  
  getAllGmpList() {
    return this.http.get(`${this.getGmpList}`);
  }
  
  getAllGuidelinesList() {
    return this.http.get(`${this.getGuidelinesList}`);
  }
  
  getAllBusinessList() {
    return this.http.get(`${this.getBusinessList}`);
  }
  
  getAllProductList() {
    return this.http.get(`${this.getProductList}`);
  }
  
  getAllVideoList() {
    return this.http.get(`${this.getVideoList}`);
  }
  
  getAllSubCatNewsList() {
    return this.http.get(`${this.getSubCatNewsList}`);
  }
  
  getAllSubCatGmpList() {
    return this.http.get(`${this.getSubCatGmpList}`);
  }
  
  getAllSubCatGuidelinesList() {
    return this.http.get(`${this.getSubCatGuidelinesList}`);
  }
  
  getAllSubCatBusinessList() {
    return this.http.get(`${this.getSubCatBusinessList}`);
  }
  
  getAllSubCatProductList() {
    return this.http.get(`${this.getSubCatProductList}`);
  }

  postsubcatVideo(requestParameters) {
    return this.http.post(`${this.postsubcategoryVideo}`, JSON.parse(requestParameters), {});
  }

  getAllsubcatVideo() {
    return this.http.get(`${this.getAllsubcategoryVideo}`);
  }

  updatesubcatVideo(requestParameters, id) {
    return this.http.put(`${this.updatesubcategoryVideo}?id=${id}`, requestParameters, {});
  }

  getsubcatVideoById(requestParameters) {
    return this.http.post(`${this.getsubcategoryVideoById}`, JSON.parse(requestParameters), {});
  }

  deletesubcatVideo(requestParameters) {
    return this.http.delete(`${this.deletesubcategoryVideo}?id=${requestParameters}`);
  }

  getAllDashboardCount() {
    return this.http.get(`${this.dashboardCount}`);
  }
  
  AddFooterContent(requestParameters){
    return this.http.post(`${this.postFooterData}`,requestParameters)
  }

  addprivacypolicy(requestParameters){
    return this.http.post(`${this.addprivacy}`,requestParameters)
  }

  getAboutUsContent(){
    return this.http.get(`${this.getAboutUsData}`)
  }
  getDisclaimerContent(){
    return this.http.get(`${this.getDisclaimerData}`)
  }
  getTermsConditionsContent(){
    return this.http.get(`${this.getTermsAndConditionData}`)
  }
  getPrivacyPolicyContent(){
    return this.http.get(`${this.getPrivacyPolicyData}`)
  }
  editAboutUsContent(id, data){
    return this.http.put(`${this.editAboutUs}?id=${id}`, data)
  }

  editdiscl(id, data){
    return this.http.put(`${this.editdisclaimer}?id=${id}`, data)
  }

  editprivacy(id, data){
    return this.http.put(`${this.editprivacypolicy}?id=${id}`, data)
  }

  editterm(id, data){
    return this.http.put(`${this.edittermcondition}?id=${id}`, data)
  }
  aboutUsList(){
    return this.http.get(`${this.getAboutUsList}`)
  }
  privacypolicyList(){
    return this.http.get(`${this.getPrivacyPolicyList}`)
  }
  termsconditionsList(){
    return this.http.get(`${this.getTermsConditionsList}`)
  }
  disclaimerList(){
    return this.http.get(`${this.getDisclaimerList}`)
  }
  getAboutUsById(requestParameters){
    return this.http.post(`${this.getAboutUsDataById}`,requestParameters)
  }
  getPrivacyPolicyById(requestParameters){
    return this.http.post(`${this.getPrivacyPolicyDataById}`,requestParameters)
  }
  getTermsConditionsById(requestParameters){
    return this.http.post(`${this.getTermsConditionsDataById}`,requestParameters)
  }
  getDisclaimerById(requestParameters){
    return this.http.post(`${this.getDisclaimerDataById}`,requestParameters)
  }
  termcondition(requestParameters){
    return this.http.post(`${this.addtermcondition}`,requestParameters)
  }
}
