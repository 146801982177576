import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-dashboard-video-list',
  templateUrl: './dashboard-video-list.component.html',
  styleUrls: ['./dashboard-video-list.component.scss']
})
export class DashboardVideoListComponent implements OnInit, OnDestroy {
  private category: string;
  dtOptions: DataTables.Settings = {};
  public allData: any;
  private isDtInitialized: boolean = false;
  public addCatName: string;
  public addCatRoute: string;
  public editCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(
    private router: Router, 
    private apiService: ApiService, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.showItem();
  }

  async showItem() {
    const that = this;
    await this.apiService.getAllVideoList().subscribe((resp: any) => {
      console.log(resp.data);
      that.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async deleteItem(id, type) {
    if (id == undefined || id == '') {
      this.toastr.error('User Id is Invalid');
      return false;
    }
    if (
      id != undefined &&
      id != '' &&
      confirm('Are You Sure, You Want To Delete?')
    ) {
      if (type.split(' ')[0] === 'Internal') {
        await this.apiService.deleteVideoById(id).subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      } else if (type.split(' ')[0] === 'External') {
        await this.apiService.deleteExternalVideoById(id).subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
