<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
            <form [formGroup]="updateMembershipDaysForm" (ngSubmit)="updateMembershipDays()">
                <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="title"
                            >Membership Days<span class="required"></span
                          ></label>
                          <input
                            type="number"
                            class="form-control"
                            formControlName="membershipDays"
                            placeholder="Enter Membership Days"
                          />
                        </div>
                      </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <app-button
                      [type]="'submit'"
                      [block]="false"
                      [text]="'Submit'"
                      [loading]="isAuthLoading"
                      [color]="'info'"
                      [disabled]="isAuthDisabled"
                    ></app-button>
                    <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="['/gmp-list']">Cancel</button>
                  </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</div>