<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
            <!-- form start -->
            <form [formGroup]="addSubcategoryForm" (ngSubmit)="addSubCategory()">
                <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="subCategory"
                            >Sub-Category Name<span class="required"></span
                          ></label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="subCategory"
                            placeholder="Enter Sub Category"
                          />
                        </div>
                      </div>
                      <div class="col-md-2"></div>
                      <div class="col-md-4">
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                class="form-check-input"
                                type="checkbox"
                                id="subCatStatus"
                                formControlName="subCatStatus"
                                (change)="addSubcategoryForm.get('subCatStatus').setValue($event.target.checked ? 'active' : 'inactive')"
                                [checked]="addSubcategoryForm.value.subCatStatus === 'active'"
                                />
                                <label for="subCatStatus" class="form-check-label">Active</label>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <!-- <button type="submit" class="btn btn-info mr-2">Submit</button> -->
                    <app-button
                      [type]="'submit'"
                      [block]="false"
                      [text]="'Submit'"
                      [loading]="isAuthLoading"
                      [color]="'info'"
                      [disabled]="isAuthDisabled"
                    ></app-button>
                    <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="[cancelSubCatRoute]">Cancel</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</div>
