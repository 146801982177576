<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <!-- form start -->
          <form [formGroup]="addNewsForm" (ngSubmit)="addNews()">
            <div class="card-header pb-3">
              <div class="row">
                <div class="col-md-3" *ngIf="news_type === null"></div>
                <div class="col-md-3" *ngIf="news_type === null || news_type.split(' ')[0] === 'Internal'">
                  <div 
                    class="custom-control custom-radio"
                    style="text-align: center"
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="internal_news"
                      formControlName="news_type"
                      value="internal_news"
                      (change)="handleChange($event)"
                      
                    />
                    <label for="internal_news" class="custom-control-label"
                      >Internal</label
                    >
                  </div>
                </div>
                <div class="col-md-3" *ngIf="news_type === null || news_type.split(' ')[0] === 'External'">
                  <div
                    class="custom-control custom-radio"
                    style="text-align: center"
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="external_news"
                      formControlName="news_type"
                      value="external_news"
                      (change)="handleChange($event)"
                      
                    />
                    <label for="external_news" class="custom-control-label"
                      >External</label
                    >
                  </div>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title"
                      >Title<span class="required"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="title"
                      placeholder="Enter Title"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Image<span class="required"></span></label>
                    <div class="input-group">
                      <!-- <i class="fas fa-times" style="font-size: 10px;margin-left:119px ;"></i> -->

                      <div class="custom-file">
                        <input
                        type="file"
                        accept="image/*"
                        (change)="onFileChange($event, 'img')"
                        class="custom-file-input"
                        aria-describedby="imageFile"
                        name="img"
                        />
                        <!-- <div class="input-group-append">

                          <button>
                            <i class="fas fa-times" style="font-size: 10px;margin-left:119px ;"></i>
                          </button>
                        </div> -->
                        
                        <label class="custom-file-label" #importImg for="img">
                          <span>Upload Image</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-4"
                  *ngIf="addNewsForm.value.news_type == 'internal_news'"
                >
                  <div class="form-group">
                    <label>Video</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept="video/*"
                          (change)="onFileChange($event, 'video')"
                          class="custom-file-input"
                          name="video"
                          id="video"
                        />
                        <label class="custom-file-label" #importVdo for="video">
                          <span>Upload Video</span>
                        </label>
                      </div>
                    </div>
                    <!-- <div class="alert alert-light fade show p-0" role="alert" *ngIf="addNewsForm?.value?.video">
                      <small>{{ addNewsForm.value.video.name ? addNewsForm.value.video.name : addNewsForm.value.video.split("/")[1] }}</small>
                      <button type="button" class="btn close p-0" (click)="removeUploadedFile('video')" aria-label="Close">
                        <span aria-hidden="true">x</span>
                      </button>
                    </div> -->
                  </div>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="addNewsForm.value.news_type == 'external_news'"
                >
                  <div class="form-group">
                    <label>Link<span class="required"></span></label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-link"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="link"
                        placeholder="Enter Link"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="addNewsForm.value.news_type == 'external_news'" >
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="logo_title"
                      >Logo Title<span class="required"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="logo_title"
                      placeholder="Logo Title"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Logo Image</label>
                   
                    <div class="input-group">
                      <div class="custom-file">                        
                        <input
                          type="file"
                          accept="image/*"
                          (change)="onFileChange($event, 'logo_img')"
                          class="custom-file-input"
                          aria-describedby="imageFile"
                          name="logo_img"
                          id= "logo_img"
                         
                         
                        />
                       
                        <label class="custom-file-label" #importLogoImg for="logo_img">
                          <span>Upload Logo Image</span>
                          <!-- <button class ="custom-control" type="button" id="removebtn"  (click)="removeUploadedFile('logo_img')" aria-label="Clear" role="button">
                            <span aria-hidden="true" #importRemoveBtn for="removebtn" >x</span>
                          </button> -->

                        </label>
                      
                        <!-- <a href="#" (click)="removeUploadedFile('logo_img')">Remove</a> -->
                     

                      </div>
                    </div>
                    <!-- <div class="alert alert-light fade show p-0" role="alert" *ngIf="addNewsForm?.value?.logo_img?.name">
                      <small>{{ addNewsForm.value.logo_img.name ? addNewsForm.value.logo_img.name : '' }}</small>
                      <button type="button" class="btn close p-0" (click)="removeUploadedFile('logo_img')" aria-label="Close">
                        <span aria-hidden="true">x</span>
                      </button>
                    </div> -->
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Logo Link</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-link"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="logo_link"
                        placeholder="Logo Link"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- <div class="col-md-4">
                  <div class="form-group">
                    <label>Thumbnail Image<span class="required"></span></label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept="image/*"
                          (change)="onFileChange($event, 'thumbnail')"
                          class="custom-file-input"
                          aria-describedby="imageFile"
                          name="thumbnail"
                          id="thumbnail"
                        />
                        <label class="custom-file-label" #importThumbnailImg for="thumbnail">
                          <span>Upload Thumbnail Image</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div 
                  class="col-md-4"
                  *ngIf="addNewsForm.value.news_type == 'internal_news'"
                >
                  <div class="form-group">
                    <label>File (.pdf)</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept=".pdf, application/pdf"
                          (change)="onFileChange($event, 'files')"
                          class="custom-file-input"
                          name="files"
                          id="files"
                        />
                        <!-- accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                      text/plain, application/pdf" -->
                        <label class="custom-file-label" #importFile for="files">
                          <span>Upload File</span>
                        </label>
                      </div>
                    </div>
                    <!-- <div class="alert alert-light fade show p-0" role="alert" *ngIf="addNewsForm?.value?.files?.name">
                      <small>{{ addNewsForm.value.files.name ? addNewsForm.value.files.name : '' }}</small>
                      <button type="button" class="btn close p-0" (click)="removeUploadedFile('files')" aria-label="Close">
                        <span aria-hidden="true">x</span>
                      </button>
                    </div> -->
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Select Sub-category<span class="required"></span
                      ></label>
                    <!-- <select class="custom-select" (change)="changeSubCat($event)" formControlName="subCatName">
                      <option value="All">All</option>
                      <option *ngFor="let subCat of SubCats" value="{{ subCat }}">{{ subCat }}</option>
                    </select> -->
                    <ng-select placeholder="Select Sub-category" [multiple]="true" formControlName="subCatName">
                      <ng-option *ngFor="let subCat of SubCats" [value]="subCat">{{ subCat }}</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Active Till<span class="required"></span></label>
                    <input type="date" class="form-control" ngbDatepicker (click)="datePicker.toggle()" #datePicker="ngbDatepicker" formControlName="activeTillDate" [minDate]="minDate" placeholder="yyyy-mm-dd">
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="cancelCatRoute === '/news-list' && addNewsForm.value.news_type == 'internal_news'">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="ad_title"
                      >Advertisement Title</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="ad_title"
                      placeholder="Advertisement Title"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Advertisement Image</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept="image/*"
                          (change)="onFileChange($event, 'ad_img')"
                          class="custom-file-input"
                          aria-describedby="imageFile"
                          name="ad_img"
                        />
                        <label class="custom-file-label" #importAdImg for="ad_img">
                          <span>Upload Advertisement Image</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                *ngIf="addNewsForm.value.news_type == 'internal_news'"
              >
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="class_name"
                      >Content<span class="required"></span
                    ></label>
                    <ckeditor [config]="config_ck" formControlName="news_content">
                      <!-- <ckbutton
                        [name]="'saveButton'"
                        [command]="'saveCmd'"
                        [label]="'News Content'"
                      >
                      </ckbutton> -->
                    </ckeditor>
                  </div>
                </div>
              </div>

              <div class="row"></div>
              <div class="row">
                <div class="col-md-4">
                  <div class="custom-control custom-checkbox">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="status"
                      formControlName="status"
                      (change)="addNewsForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                      [checked]="addNewsForm.value.status == 'active'"
                    />
                    <label for="status" class="form-check-label">Active</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="card-footer text-right">
              <!-- <button type="submit" class="btn btn-info mr-2">Submit</button> -->
              <app-button
                [type]="'submit'"
                [block]="false"
                [text]="'Submit'"
                [loading]="isAuthLoading"
                [color]="'info'"
                [disabled]="isAuthDisabled"
              ></app-button>
              <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="[cancelCatRoute]">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ================================================================================================ -->
