import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { ApiService } from 'src/app/utils/services/api.service';
declare var $: any;

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  public userData = JSON.parse(localStorage.getItem("userInfo"));
  public CategoryName: any;
  
  constructor(private appService: AppService, private apiService: ApiService) {
    this.appService.listen().subscribe((res:any) => {
      this.CategoryName = res;
    });
  }

  ngOnInit() {
    // $('[data-widget="treeview"]').Treeview('init');
    this.getAllCategoryName();
    
  }

  ngAfterViewInit() {
    // this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  getAllCategoryName() {
    this.apiService.getAllCategory().subscribe((res: any) => {
      if(res.success) {
        this.CategoryName = res;
      } else {
        console.log("Problem Getting Category Names");
      }
    });
  }
  scroll(){
    document.getElementById('scroll').scrollIntoView({behavior:'smooth'})
  }
}
