import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  addNewsForm: FormGroup;
  private category: string;
  public minDate = undefined;
  private news_id: any;
  public news_type: any;
  public SubCats: any;
  private _userId: string = localStorage.getItem("id");
  public cancelCatRoute: string;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    { name: 'links' }, { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    { name: 'styles' },
    { name: 'colors' }],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'justify',
    colorButton_foreStyle: {
       element: 'font',
       attributes: { 'color': '#(color)' }
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div'
 }

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  @ViewChild('importImg', { static: false }) importImg: ElementRef;
  @ViewChild('importVdo', { static: false }) importVdo: ElementRef;
  @ViewChild('importFile', { static: false }) importFile: ElementRef;
  @ViewChild('importLogoImg', { static: false }) importLogoImg: ElementRef;
  @ViewChild('importAdImg', { static: false }) importAdImg: ElementRef;
  // @ViewChild('importRemoveBtn', { static: false }) importRemoveBtn: ElementRef;
  // @ViewChild('importThumbnailImg', { static: false }) importThumbnailImg: ElementRef;

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    // console.log(this.category);
    if(this.category === "news") {
      this.cancelCatRoute = '/news-list';
    } 
    // else if(this.category === "gmp") {
    //   this.cancelCatRoute = '/gmp-list';
    // } else if(this.category === "regulatory-guidelines") {
    //   this.cancelCatRoute = '/regulatory-guidelines-list';
    // } 
    else if(this.category === "business") {
      this.cancelCatRoute = '/business-list';
    } else if(this.category === "products-services") {
      this.cancelCatRoute = '/products-services-list';
    }

    this.addNewsForm = new FormGroup({
      news_type: new FormControl(),
      title: new FormControl(),
      logo_title: new FormControl(),
      ad_title: new FormControl(),
      link: new FormControl(),
      logo_link: new FormControl(),
      news_content: new FormControl(),
      img: new FormControl(),
      // thumbnail: new FormControl(),
      logo_img: new FormControl(),
      ad_img: new FormControl(),
      video: new FormControl(),
      files: new FormControl(),
      subCatName: new FormControl(),
      activeTillDate: new FormControl(),
      status: new FormControl(),
    });
    this.addNewsForm = this.formBuilder.group({
      news_type: ['', []],
      title: ['', [Validators.required]],
      logo_title: ['', [Validators.required]],
      ad_title: [''],
      link: ['', [Validators.required]],
      logo_link: ['', [Validators.required]],
      news_content: ['', [Validators.required]],
      img: ['', [Validators.required]],
      // thumbnail: ['', [Validators.required]],
      logo_img: ['', [Validators.required]],
      ad_img: [''],
      video: ['', []],
      files: ['', []],
      subCatName: [[], [Validators.required]],
      activeTillDate: [null, Validators.required],
      status: ['', []],
    });
    
    this.getAllSubCat();

    this.news_id = this.route.snapshot.paramMap.get('news_id');
    this.news_type = this.route.snapshot.paramMap.get('news_type');
    // console.log(this.news_id, this.news_type);
    if (this.news_id != undefined && this.news_id != '' && this.news_type != undefined && this.news_type != '') {
      this.editNews(this.news_id, this.news_type.split(" ")[0]);
    } else {
      this.addNewsForm.get('news_type').setValue('internal_news');
      this.addNewsForm.get('status').setValue('active');
      // this.addNewsForm.get('subCatName').setValue('All', { onlySelf: true });
    }
  }

  getAllSubCat() {
    let catTypeSubCatApi: any;
    if(this.category === "news") {
      catTypeSubCatApi = this.apiService.getAllSubCatNewsList();
    } 
    // else if(this.category === "gmp") {
    //   catTypeSubCatApi = this.apiService.getAllSubCatGmpList();
    // } else if(this.category === "regulatory-guidelines") {
    //   catTypeSubCatApi = this.apiService.getAllSubCatGuidelinesList();
    // } 
    else if(this.category === "business") {
      catTypeSubCatApi = this.apiService.getAllSubCatBusinessList();
    } else if(this.category === "products-services") {
      catTypeSubCatApi = this.apiService.getAllSubCatProductList();///////////
    }
    catTypeSubCatApi.subscribe((resp: any) => {
      this.SubCats = [];
      for(let i = 0; i < resp.data.length; i++) {
        if(resp.data[i].status === "active") {
          this.SubCats.push(resp.data[i].sub_category_name);
        }
      }
    });
  }

  onFileChange(event, set) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addNewsForm.get(set).setValue(file);
    }
    if(set === 'img') {
      this.importImg.nativeElement.innerText = event.target.files[0].name + "<i class='fas fa-times' ></i>"  ;
    } else if(set === 'video') {
      this.importVdo.nativeElement.innerText = event.target.files[0].name;
    } else if(set === 'files') {
      this.importFile.nativeElement.innerText = event.target.files[0].name;
    // } else if (set === 'thumbnail') {
    //   this.importThumbnailImg.nativeElement.innerText = event.target.files[0].name;
    } else if(set === 'logo_img') {
      this.importLogoImg.nativeElement.innerText = event.target.files[0].name;
    } else if(set === 'ad_img') {
      this.importAdImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  // removeUploadedFile(set) {
  //   this.addNewsForm.get(set).setValue('');
  //   if (set === 'video') {
  //     this.importVdo.nativeElement.innerText = 'Upload Video';
  //   } else if(set === 'files') {
  //     this.importFile.nativeElement.innerText = 'Upload Files';
  //   } else if(set === 'logo_img') {
  //     this.importLogoImg.nativeElement.innerText = 'Upload Logo Image';
  //   }
  // }


  async addNews() {
    if (
      this.addNewsForm.value.title.trim() == undefined ||
      this.addNewsForm.value.title.trim() == ''
    ) {
      this.toastr.error('Please Enter Title');
      return false;
    }
    if (this.addNewsForm.value.news_type == 'external_news') {
      if (
        this.addNewsForm.value.link.trim() == undefined ||
        this.addNewsForm.value.link.trim() == ''
      ) {
        this.toastr.error('Please Enter Link');
        return false;
      }
      if (
        this.addNewsForm.value.logo_title.trim() == undefined ||
        this.addNewsForm.value.logo_title.trim() == ''
      ) {
        this.toastr.error('Please Enter Logo Title');
        return false;
      }
      // if (
      //   this.addNewsForm.value.logo_link.trim() == undefined ||
      //   this.addNewsForm.value.logo_link.trim() == ''
      // ) {
      //   this.toastr.error('Please Enter Logo Link');
      //   return false;
      // }
    }
    if (this.addNewsForm.value.news_type == 'internal_news') {
      if (
        this.addNewsForm.value.news_content.trim() == undefined ||
        this.addNewsForm.value.news_content.trim() == ''
      ) {
        this.toastr.error('Please Enter Content');
        return false;
      }
    }
    if (this.addNewsForm.value.subCatName.length === 0 ) {
      this.toastr.error('Please Select Sub-category');
      return false;
    }
    if (
      this.addNewsForm.value.activeTillDate == undefined ||
      this.addNewsForm.value.activeTillDate == '' || 
      this.addNewsForm.value.activeTillDate == null 
    ) {
      this.toastr.error('Please Enter Active Till Date');
      return false;
    }
    if (this.news_id != undefined && this.news_id != '' && this.news_type != undefined && this.news_type != '') {
      this.updateNews(this.news_id, this.news_type.split(" ")[0]);
    } else {
      
      if (
        this.addNewsForm.value.img.name == undefined ||
        this.addNewsForm.value.img.name == ''
      ) {
        this.toastr.error('Please Select Image');
        return false;
      }
      // if (
      //   this.addNewsForm.value.thumbnail.name == undefined ||
      //   this.addNewsForm.value.thumbnail.name == ''
      // ) {
      //   this.toastr.error('Please Select Thumbnail Image');
      //   return false;
      // }
      // if (this.addNewsForm.value.news_type == 'external_news') {
      //   if (
      //     this.addNewsForm.value.logo_img.name == undefined ||
      //     this.addNewsForm.value.logo_img.name == ''
      //   ) {
      //     this.toastr.error('Please Select Logo Image');
      //     return false;
      //   }
      // }

      // console.log(
      //   this.addNewsForm.value.news_type,
      //   this.addNewsForm.value.title,
      //   this.addNewsForm.value.logo_title,
      //   this.addNewsForm.value.link,
      //   this.addNewsForm.value.logo_link,
      //   this.addNewsForm.value.news_content,
      //   this.addNewsForm.value.img,
      //   this.addNewsForm.value.thumbnail,
      //   this.addNewsForm.value.logo_img,
      //   this.addNewsForm.value.video,
      //   this.addNewsForm.value.files,
      //   this.addNewsForm.value.subCatName,
      //   this.addNewsForm.value.activeTillDate,
      //   this.addNewsForm.value.status,
      // );
      // return false;
      let date = `${this.addNewsForm.value.activeTillDate.month}/${this.addNewsForm.value.activeTillDate.day}/${this.addNewsForm.value.activeTillDate.year}`
      console.log(date)

      let formData = new FormData();
      if (this.addNewsForm.value.news_type == 'external_news') {
        formData.append('all_images', this.addNewsForm.get('img').value);
        formData.append('all_images2', this.addNewsForm.get('logo_img').value);
        formData.append('status', this.addNewsForm.get('status').value);
        formData.append('user_id', this._userId);
        formData.append('title', this.addNewsForm.get('title').value);
        formData.append('logo_title', this.addNewsForm.get('logo_title').value);
        formData.append('link', this.addNewsForm.get('link').value);
        formData.append('logo_link', this.addNewsForm.get('logo_link').value);
        // formData.append('thumb_file', this.addNewsForm.get('thumbnail').value);
        for(let i = 0; i < this.addNewsForm.value.subCatName.length; i++) {
          formData.append('sub[]', this.addNewsForm.get('subCatName').value[i]);
        }
        formData.append('active_till', date);
        // console.log(formData);
        this.isAuthLoading = true;
        this.isAuthDisabled = true;
        let catTypeExAddApi: any;
        if(this.category === "news") {
          catTypeExAddApi = this.apiService.addExternalNews(formData);
        } 
        // else if(this.category === "gmp") {
        //   catTypeExAddApi = this.apiService.addExternalGmp(formData);
        // } else if(this.category === "regulatory-guidelines") {
        //   catTypeExAddApi = this.apiService.addExternalGuidelines(formData);
        // } 
        else if(this.category === "business") {
          catTypeExAddApi = this.apiService.addExternalBusiness(formData);
        } else if(this.category === "products-services") {
          catTypeExAddApi = this.apiService.addExternalProduct(formData);
        }
        await catTypeExAddApi.subscribe((result) => {
          if (result.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.success(result.message);
            if(this.category === "news") {
              this.router.navigateByUrl('/news-list');
            } 
            // else if(this.category === "gmp") {
            //   this.router.navigateByUrl('/gmp-list');
            // } else if(this.category === "regulatory-guidelines") {
            //   this.router.navigateByUrl('/regulatory-guidelines-list');
            // } 
            else if(this.category === "business") {
              this.router.navigateByUrl('/business-list');
            } else if(this.category === "products-services") {
              this.router.navigateByUrl('/products-services-list');
            }
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      } else if (this.addNewsForm.value.news_type == 'internal_news') {
        formData.append('all_images', this.addNewsForm.get('img').value);
        formData.append('all_video', this.addNewsForm.get('video').value);
        formData.append('all_file', this.addNewsForm.get('files').value);
        // formData.append('thumb_file', this.addNewsForm.get('thumbnail').value);
        formData.append('active_till', date);
        formData.append('status', this.addNewsForm.get('status').value);
        formData.append('user_id', this._userId);
        formData.append('title', this.addNewsForm.get('title').value);
        formData.append('news_content', this.addNewsForm.get('news_content').value);
        for(let i = 0; i < this.addNewsForm.value.subCatName.length; i++) {
          formData.append('sub[]', this.addNewsForm.get('subCatName').value[i]);
        }
        if(this.category === "news") {
          formData.append('advertisementTitle', this.addNewsForm.get('ad_title').value);
          formData.append('ads_logo', this.addNewsForm.get('ad_img').value);
        }
        this.isAuthLoading = true;
        this.isAuthDisabled = true;
        let catTypeInAddApi: any;
        if(this.category === "news") {
          catTypeInAddApi = this.apiService.addInternalNews(formData);
        } 
        // else if(this.category === "gmp") {
        //   catTypeInAddApi = this.apiService.addInternalGmp(formData);
        // } else if(this.category === "regulatory-guidelines") {
        //   catTypeInAddApi = this.apiService.addInternalGuidelines(formData);
        // } 
        else if(this.category === "business") {
          catTypeInAddApi = this.apiService.addInternalBusiness(formData);
        } else if(this.category === "products-services") {
          catTypeInAddApi = this.apiService.addInternalProduct(formData);
        }
        await catTypeInAddApi.subscribe((result) => {
          if (result.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.success(result.message);
            if(this.category === "news") {
              this.router.navigateByUrl('/news-list');
            } 
            // else if(this.category === "gmp") {
            //   this.router.navigateByUrl('/gmp-list');
            // } else if(this.category === "regulatory-guidelines") {
            //   this.router.navigateByUrl('/regulatory-guidelines-list');
            // } 
            else if(this.category === "business") {
              this.router.navigateByUrl('/business-list');
            } else if(this.category === "products-services") {
              this.router.navigateByUrl('/products-services-list');
            }
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      }
    }
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  async editNews(id, type) {
    let dataForForm = {
      id: id,
    };
    // console.log(dataForForm);
    if (type === 'Internal') {
      let catTypeInEditApi: any;
      if(this.category === "news") {
        catTypeInEditApi = this.apiService.getInNewsById(dataForForm);
      } 
      // else if(this.category === "gmp") {
      //   catTypeInEditApi = this.apiService.getInGmpById(dataForForm);
      // } else if(this.category === "regulatory-guidelines") {
      //   catTypeInEditApi = this.apiService.getInGuidelinesById(dataForForm);
      // } 
      else if(this.category === "business") {
        catTypeInEditApi = this.apiService.getInBusinessById(dataForForm);
      } else if(this.category === "products-services") {
        catTypeInEditApi = this.apiService.getInProductById(dataForForm);
      }
      await catTypeInEditApi.subscribe((result) => {
        if (result.success) {
          // console.log(result.getData);
          let newsData: any = result.getData;
          this.addNewsForm.get('news_type').setValue('internal_news');
          this.addNewsForm.get('subCatName').setValue(newsData.sub_category_name);
          let date = new Date(newsData.active_till);
          this.addNewsForm.patchValue({
            title: newsData.title,
            img: newsData.news_image[0]?.src,
            video: newsData.news_video[0]?.src,
            files: newsData.news_file[0]?.src,
            // thumbnail: newsData.thumbnail_img[0]?.src,
            ad_title: newsData.advertisementTitle,
            ad_img: newsData.advertisementLogo[0]?.src,
            news_content: newsData.news_content,
            activeTillDate: {
              day: date.getUTCDate() + 1,
              month: date.getUTCMonth() + 1,
              year: date.getUTCFullYear()
          },
          });
          if(newsData.status == "active") {
            this.addNewsForm.get('status').setValue('active');
          }
          setTimeout(() => {
            this.importImg.nativeElement.innerText = newsData.news_image[0]?.orgName;
            if(
              newsData.news_video[0]?.orgName === undefined || 
              newsData.news_video[0]?.orgName === '' || 
              newsData.news_video[0]?.orgName ===  null
            ) {
              this.importVdo.nativeElement.innerText = 'Upload Video';  
            } else {
              this.importVdo.nativeElement.innerText = newsData.news_video[0]?.orgName;
            }
            if(
              newsData.news_file[0]?.orgName === undefined || 
              newsData.news_file[0]?.orgName === '' || 
              newsData.news_file[0]?.orgName === null
            ) {
              this.importFile.nativeElement.innerText = 'Upload File';  
            } else {
              this.importFile.nativeElement.innerText = newsData.news_file[0]?.orgName;
            }
            if(this.category === "news") {
              if(
                newsData.advertisementLogo[0]?.orgName === undefined || 
                newsData.advertisementLogo[0]?.orgName === '' || 
                newsData.advertisementLogo[0]?.orgName === null
              ) {
                this.importAdImg.nativeElement.innerText = 'Upload Advertisement Image';  
              } else {
                this.importAdImg.nativeElement.innerText = newsData.advertisementLogo[0]?.orgName;
              }
            }
            // this.importThumbnailImg.nativeElement.innerText = newsData.thumbnail_img[0]?.orgName;
          }, 1000);
        } else {
          this.toastr.error(result.message);
        }
      });
    } else if (type === 'External') {
      let catTypeExEditApi: any;
      if(this.category === "news") {
        catTypeExEditApi = this.apiService.getExNewsById(dataForForm);
      } 
      // else if(this.category === "gmp") {
      //   catTypeExEditApi = this.apiService.getExGmpById(dataForForm);
      // } else if(this.category === "regulatory-guidelines") {
      //   catTypeExEditApi = this.apiService.getExGuidelinesById(dataForForm);
      // } 
      else if(this.category === "business") {
        catTypeExEditApi = this.apiService.getExBusinessById(dataForForm);
      } else if(this.category === "products-services") {
        catTypeExEditApi = this.apiService.getExProductById(dataForForm);
      }
      await catTypeExEditApi.subscribe((result) => {
        if (result.success) {
          console.log(result.getData);
          let newsData: any = result.getData;
          this.addNewsForm.get('news_type').setValue('external_news');
          this.addNewsForm.get('subCatName').setValue(newsData.sub_category_name);
          let date = new Date(newsData.active_till);
          this.addNewsForm.patchValue({
            title: newsData.title,
            img: newsData.news_image[0]?.src,
            // thumbnail: newsData.thumbnail_img[0]?.src,
            link: newsData.link,
            logo_title: newsData.logo_title,
            logo_img: newsData.logo_image[0]?.src,
            logo_link: newsData.logo_link,
            activeTillDate: {
              day: date.getUTCDate() + 1,
              month: date.getUTCMonth() + 1,
              year: date.getUTCFullYear()
            },
          });
          if(newsData.status == "active") {
            this.addNewsForm.get('status').setValue('active');
          }
          setTimeout(() => {
            this.importImg.nativeElement.innerText = newsData.news_image[0]?.orgName;
            if(
              newsData.logo_image[0]?.orgName === undefined || 
              newsData.logo_image[0]?.orgName === '' || 
              newsData.logo_image[0]?.orgName === null
            ) {
              this.importLogoImg.nativeElement.innerText = 'Upload Logo Image';  
            } else {
              this.importLogoImg.nativeElement.innerText = newsData.logo_image[0]?.orgName;
            }
            // this.importLogoImg.nativeElement.innerText = newsData.logo_image[0]?.orgName;
            // this.importThumbnailImg.nativeElement.innerText = newsData.thumbnail_img[0]?.orgName;
          }, 1000);
        } else {
          this.toastr.error(result.message);
        }
      });
    } else {
      this.toastr.error('No Data Found');
    }
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////
  async updateNews(id, type) {
    let formData = new FormData();
    if(
      this.addNewsForm.value.img?.name != undefined ||
      this.addNewsForm.value.img?.name != ''
    ) {
      formData.append('all_images', this.addNewsForm.get('img').value);
    }
    // if (
    //   this.addNewsForm.value.thumbnail.name != undefined ||
    //   this.addNewsForm.value.thumbnail.name != ''
    // ) {
    //   formData.append('thumb_file', this.addNewsForm.get('thumbnail').value);
    // }

    if (type == 'External') {
      if (
        this.addNewsForm.value.logo_img?.name != undefined ||
        this.addNewsForm.value.logo_img?.name != ''
      ) {
        formData.append('all_images2', this.addNewsForm.get('logo_img').value);
      } 
    }

    if (type == 'Internal') {
      // if(this.addNewsForm.value.video !== '') {
      //   if(
      //     this.addNewsForm.value.video?.name != undefined ||
      //     this.addNewsForm.value.video?.name != ''
      //   ) {
      //     formData.append('all_video', this.addNewsForm.get('video').value);
      //   }
      // } else {
      //   formData.append('all_video', this.addNewsForm.get('video').value);
      // }

      if(
        this.addNewsForm.value.video?.name != undefined ||
        this.addNewsForm.value.video?.name != ''
      ) {
        formData.append('all_video', this.addNewsForm.get('video').value);
      }
      if(
        this.addNewsForm.value.files?.name != undefined ||
        this.addNewsForm.value.files?.name != ''
      ) {
        formData.append('all_file', this.addNewsForm.get('files').value);
      }
      if(this.category === 'news') {
        if(
          this.addNewsForm.value.ad_img?.name != undefined ||
          this.addNewsForm.value.ad_img?.name != ''
        ) {
          formData.append('ads_logo', this.addNewsForm.get('ad_img').value);
        }
      }
    }

    let date = `${this.addNewsForm.value.activeTillDate.month}/${this.addNewsForm.value.activeTillDate.day}/${this.addNewsForm.value.activeTillDate.year}`
    console.log(date);

      if (type == 'External') {
        formData.append('status', this.addNewsForm.get('status').value == "" || this.addNewsForm.get('status').value == "inactive" ? "inactive" : "active");
        formData.append('user_id', this._userId);
        formData.append('title', this.addNewsForm.get('title').value);
        formData.append('link', this.addNewsForm.get('link').value);
        formData.append('logo_title', this.addNewsForm.get('logo_title').value);
        formData.append('logo_link', this.addNewsForm.get('logo_link').value);
        for(let i = 0; i < this.addNewsForm.value.subCatName.length; i++) {
          formData.append('sub[]', this.addNewsForm.get('subCatName').value[i]);
        }
        // formData.append('sub_category_name', this.addNewsForm.get('subCatName').value);
        formData.append('active_till', date);

        // console.log(formData);
        this.isAuthLoading = true;
        this.isAuthDisabled = true;
        let catTypeExUpdateApi: any;
        if(this.category === "news") {
          catTypeExUpdateApi = this.apiService.updateExternalNews(formData, id);
        } 
        // else if(this.category === "gmp") {
        //   catTypeExUpdateApi = this.apiService.updateExternalGmp(formData, id);
        // } else if(this.category === "regulatory-guidelines") {
        //   catTypeExUpdateApi = this.apiService.updateExternalGuidelines(formData, id);
        // } 
        else if(this.category === "business") {
          catTypeExUpdateApi = this.apiService.updateExternalBusiness(formData, id);
        } else if(this.category === "products-services") {
          catTypeExUpdateApi = this.apiService.updateExternalProduct(formData, id);//////////////////
        }
        await catTypeExUpdateApi.subscribe((result) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            if(this.category === "news") {
              this.router.navigateByUrl('/news-list');
            } 
            // else if(this.category === "gmp") {
            //   this.router.navigateByUrl('/gmp-list');
            // } else if(this.category === "regulatory-guidelines") {
            //   this.router.navigateByUrl('/regulatory-guidelines-list');
            // } 
            else if(this.category === "business") {
              this.router.navigateByUrl('/business-list');
            } else if(this.category === "products-services") {
              this.router.navigateByUrl('/products-services-list');
            }
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      } else if (type == 'Internal') {
        formData.append('status', this.addNewsForm.get('status').value == "" || this.addNewsForm.get('status').value == "inactive" ? "inactive" : "active");
        formData.append('user_id', this._userId);
        formData.append('title', this.addNewsForm.get('title').value);
        formData.append('news_content', this.addNewsForm.get('news_content').value);
        for(let i = 0; i < this.addNewsForm.value.subCatName.length; i++) {
          formData.append('sub[]', this.addNewsForm.get('subCatName').value[i]);
        }
        if(this.category === "news") {
          formData.append('advertisementTitle', this.addNewsForm.get('ad_title').value);
        }
        // formData.append('sub_category_name', this.addNewsForm.get('subCatName').value);
        formData.append('active_till', date);
        // console.log(formData);
        this.isAuthLoading = true;
        this.isAuthDisabled = true;
        let catTypeInUpdateApi: any;
        if(this.category === "news") {
          catTypeInUpdateApi = this.apiService.updateInternalNews(formData, id);
        } 
        // else if(this.category === "gmp") {
        //   catTypeInUpdateApi = this.apiService.updateInternalGmp(formData, id);
        // } else if(this.category === "regulatory-guidelines") {
        //   catTypeInUpdateApi = this.apiService.updateInternalGuidelines(formData, id);
        // } 
        else if(this.category === "business") {
          catTypeInUpdateApi = this.apiService.updateInternalBusiness(formData, id);
        } else if(this.category === "products-services") {
          catTypeInUpdateApi = this.apiService.updateInternalProduct(formData, id);///////////////////
        }
        await catTypeInUpdateApi.subscribe((result) => {
          if (result.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.success(result.message);
            if(this.category === "news") {
              this.router.navigateByUrl('/news-list');
            } 
            // else if(this.category === "gmp") {
            //   this.router.navigateByUrl('/gmp-list');
            // } else if(this.category === "regulatory-guidelines") {
            //   this.router.navigateByUrl('/regulatory-guidelines-list');
            // } 
            else if(this.category === "business") {
              this.router.navigateByUrl('/business-list');
            } else if(this.category === "products-services") {
              this.router.navigateByUrl('/products-services-list');
            }
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      }
  }
  // formReset(){
  //   // this.addNewsForm.value.title == '',
  //   // this.addNewsForm.value.subCatName == '',
  //   // this.addNewsForm.value.img == '',
  //   // this.addNewsForm.value.activeTillDate == ''

  //   // this.addNewsForm.get('news_type').setValue('internal_news');
  //   console.log('reset'+ this.addNewsForm.value)
  //   console.log(this.addNewsForm.get('news_type').setValue('internal_news'))
    
  // }
  handleChange(eve){
    let formData = new FormData
    var target = eve.target;
    if(target.checked){
      // document.getElementById('').setValue('')
    this.addNewsForm.get('title').setValue('')
    this.addNewsForm.get('subCatName').setValue('')
    // formData.remove(this.addNewsForm.get('img').setValue(''),
    this.addNewsForm.get('active_till').setValue('')
    // this.addNewsForm.get('active_till')
    // formData.remove('active_till', date);
    // formData.delete('all_images');
    console.log('checked')
    }
  }

}
