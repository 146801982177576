<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <!-- form start -->
          <form [formGroup]="addPrivacyPolicyForm" (ngSubmit)="AddPrivacyContent()">
            <div class="card-header pb-3">
            
            </div>
            <div class="card-body">
              

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="class_name">Content<span class="required"></span>
                    </label>
                    <ckeditor [config]="config_ck" formControlName="privacy">
                   
                    </ckeditor>
                  </div>
                </div>
              </div>

            </div>
            <!-- /.card-body -->
            <div class="card-footer text-right">
              <!-- <button type="submit" class="btn btn-info mr-2">Submit</button> -->
              <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                [disabled]="isAuthDisabled"></app-button>
              <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2"
                [routerLink]="['/privacy-policy-list']">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>