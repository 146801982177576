import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-user-log-details',
  templateUrl: './user-log-details.component.html',
  styleUrls: ['./user-log-details.component.scss']
})
export class UserLogDetailsComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  selectedSearchDate: any;
  dateObj = undefined;
  allUser: any;
  render: number;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    const that = this;
    let currentDate = new Date;
    this.dateObj = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    }
    this.selectedSearchDate = this.dateObj;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.render = 1;
    this.searchItem(this.dateObj);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async searchItem(dateInput) {
    const that = this;
    // console.log(dateInput);
    const searchDate = { date: `${dateInput.month}/${dateInput.day}/${dateInput.year}` };
    console.log(searchDate)
    await this.apiService.getUserLoginDetails(searchDate).subscribe((resp: any) => {
      that.allUser = resp.data;
      console.log(resp);
      if(this.render === 1) {
        this.dtTrigger.next();
      }
      this.render = 0;
    });
  }

}
