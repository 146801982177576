<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <a
              [routerLink]="[addCatRoute]"
              class="btn btn-info float-right mb-3"
              >{{ addCatName }}</a
            > -->
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Content</th>
                  <!-- <th>Type</th> -->
                  <!-- <th>Status</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                <tr *ngFor="let data of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td><div class="sn-content" [innerHTML]="data.disclaimer"></div></td>
                  <!-- <td>{{ data.name.split(" ")[0] }}</td> -->
                  <!-- <td *ngIf="data.status === 'active'">Active</td>
                  <td *ngIf="data.status !== 'active'">Inactive</td> -->
                  <!-- <td>{{ news.email }}</td>
                  <td>01/21/2021</td>
                  <td>04/20/2021</td> -->
                  <td>
                    <div class="btn-group">
                      <a
                        type="button"
                        class="btn btn-info" data-bs-toggle="tooltip" title="Edit"
                        [routerLink]="[editCatRoute, data._id]"
                      >
                        <i class="fas fa-pen"></i>
                      </a>
                      <!-- <button
                        type="button"
                        class="btn btn-danger" data-bs-toggle="tooltip" title="Delete"
                        (click)="deleteItem(data._id, data.name)"
                      >
                        <i class="fas fa-trash"></i> -->
                      <!-- </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allData?.length == 0">
                <tr>
                  <td
                    colspan="5"
                    class="no-data-available"
                    style="text-align: center"
                  >
                    No data!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  
