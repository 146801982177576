import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dash-video-subcategory',
  templateUrl: './dash-video-subcategory.component.html',
  styleUrls: ['./dash-video-subcategory.component.scss']
})
export class DashVideoSubcategoryComponent implements OnInit {
  addSubcategoryForm: FormGroup;
  // private category: string;
  public cancelSubCatRoute: string;
  private subCat_id: any;
  private _userId: string = localStorage.getItem("id");
  public isAuthLoading = false;
  public isAuthDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.cancelSubCatRoute = '/video-subcategory-list';

    this.addSubcategoryForm = new FormGroup({
      subCategory: new FormControl(),
      subCatStatus: new FormControl(),
    });
    this.addSubcategoryForm = this.formBuilder.group({
      subCategory: ['', [Validators.required]],
      subCatStatus: ['', []],
    });

    this.subCat_id = this.route.snapshot.paramMap.get('subCat_id');
    if (this.subCat_id != undefined && this.subCat_id != '') {
      this.editSubCategory(this.subCat_id);
    } else {
      this.addSubcategoryForm.get('subCatStatus').setValue('active');
    }
  }

  async addSubCategory() {
    if (
      this.addSubcategoryForm.value.subCategory.trim() == undefined ||
      this.addSubcategoryForm.value.subCategory.trim() == ''
    ) {
      this.toastr.error('Please Enter Sub-Category');
      return false;
    }

    if (this.subCat_id != undefined && this.subCat_id != '') {
      this.updateNews(this.subCat_id);
    } else {
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      let formData = {
        'sub_category_name': this.addSubcategoryForm.get('subCategory').value.trim(),
        'status': this.addSubcategoryForm.get('subCatStatus').value,
        'user_id': this._userId,
      }
      // console.log(formData);
      let catTypeAddApi: any;
      catTypeAddApi = this.apiService.postsubcatVideo(JSON.stringify(formData));
      await catTypeAddApi.subscribe((result) => {
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.success(result.message);
          this.router.navigateByUrl('/video-subcategory-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.error(result.message);
        }
      });
    }
  }

  async editSubCategory(subCat_id) {
    let dataForForm = {
      id: subCat_id,
    };
    // console.log(dataForForm);
    if (subCat_id) {
      let catTypeEditApi: any;
      catTypeEditApi = this.apiService.getsubcatVideoById(JSON.stringify(dataForForm));
      await catTypeEditApi.subscribe((result) => {
        if (result.success) {
          console.log(result);
          let newsData: any = result.getData;
          this.addSubcategoryForm.patchValue({
            subCategory: newsData.sub_category_name,
          });
          if(newsData.status == "active") {
            this.addSubcategoryForm.get('subCatStatus').setValue('active');
          }
        } else {
          this.toastr.error(result.message);
        }
      });
    } else {
      this.toastr.error('No Data Found');
    }
  }

  async updateNews(subCat_id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    let formData = {
      'sub_category_name': this.addSubcategoryForm.get('subCategory').value,
      'status': this.addSubcategoryForm.get('subCatStatus').value == "" || this.addSubcategoryForm.get('subCatStatus').value == "inactive" ? "inactive" : "active",
    }
    // console.log(formData);
    let catTypeUpdateApi: any;
    catTypeUpdateApi = this.apiService.updatesubcatVideo(formData, subCat_id);
    await catTypeUpdateApi.subscribe((result) => {
      if (result.success) {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.success(result.message);
        this.router.navigateByUrl('/video-subcategory-list');
      } else {
        this.isAuthLoading = false;
        this.isAuthDisabled = false;
        this.toastr.error(result.message);
      }
    });

  }

}
