// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_HOST: 'https://pharmaapi.swapinfotech.com/', //'http://ec2-13-234-195-38.ap-south-1.compute.amazonaws.com/',//
  IMAGE_PATH: 'http://d225nbi5dijz8r.cloudfront.net/', // 'https://examassetsprivatebucket.s3.ap-south-1.amazonaws.com/'
  BUCKETNAME: 'examassetsprivatebucket',
  BUCKETREGION: 'ap-south-1',
  BUCKETACCESKEY: 'AKIAQ3K54JPZ2JLSIDOC',
  BUCKETSECRETACCESKEY: '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',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
